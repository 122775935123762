<div class="relative featured-hotel-item-container cursor-pointer rounded-md border border-palette-gray-100">
    <a 
        class="featured-hotel-item rounded-md"
        [routerLink]="genRouterLink(item.property_id, item.property_name, item.address, item.city_name)"
        [queryParams]="genQueryParams(item.property_id, item.city_name)">
    <div>
        <div class="rounded-t-lg image-container">
            <img *ngIf="item.property_image_overview_id" loading="lazy"
                class="seo-image rounded-t-md !object-cover bg-palette-gray-200"
                src="{{ baseImageId }}/{{ item.property_image_overview_id }}.webp" (error)="replaceWithDefaultImage($event)" alt="">
            <img 
                *ngIf="!item.property_image_overview_id"
                loading="lazy"
                class="seo-image rounded-t-md !object-cover bg-palette-gray-200"
                [src]="item.property_image_overview_url"
                (error)="replaceWithDefaultImage($event)"
                alt=""
            >
            
            <app-rating-score 
                *ngIf="item.average_rating"
                [rating]="getRate(item.average_rating)"
                [style]="'px-2 py-1 bg-palette-gray-900 bg-opacity-40 absolute bottom-3 left-3 text-white text-base font-semibold'"
                [tooltipStyle]="'bottom-1/2 left-[120%] translate-y-[50%] w-[180px] min-[370px]:w-[100px] min-[500px]:w-[180px]'">
            </app-rating-score>
        </div>
        <div class="bg-white !p-3 rounded-b-md">
            <div class="flex justify-start items-center relative">
                <div class="flex gap-[1px]">
                    <div 
                        [tooltip]="'Star rating is provided by the property based on their infrastructure and facilities'"
                        [position]="TooltipPosition.ABOVE"
                        [theme]="TooltipTheme.DARK_LIGHT"
                        class="!h-6"
                    >
                        <app-star 
                            [count]="item.star"
                            starStyle="!gap-0.5"
                            iconStyle="sctr-icon-solid-star-01 text-palette-yellow-400 text-sm">
                        </app-star>
                    </div>
                </div>
            </div>
            <div class="relative h-[40px]">
                <h1 
                    [tooltip]="item.property_name"
                    [isTruncated]="true"
                    class="seo-title property-name text-sm not-italic font-semibold leading-[20px] text-palette-gray-900"
                >
                    {{ item.property_name }}
            </h1>
            </div>
            <div class="flex items-center relative" (click)="openMap($event, item)">
                <common-icon-text 
                    class="!w-full text-ellipsis overflow-hidden flex items-center"
                    [icon]="'sctr-icon-marker-pin-01'"
                    [iconStyle]="'text-base !leading-5 mr-1 opacity-70'"
                    [text]="item.address"
                    [textStyle]="'text-soctrip-small font-normal text-palette-gray-500 whitespace-nowrap text-ellipsis overflow-hidden'">
                </common-icon-text>
            </div>
            <div class="h-4 !mt-[6px] grid grid-cols-8 !gap-2 [&>*:nth-child(n+9)]:hidden">
                <ng-container *ngFor="let facility of renderAmenityList(item.property_amenity_list)">
                    <div class="icon-service col-span-1 !w-4 h-4 flex items-center">
                        <i class="amenity-icon sctr-icon-{{ facility.amenity_icon }}"></i>
                    </div>
                </ng-container>
            </div>
            <!-- <div *ngIf="!isOverflowPrice" #containerElementRef class="flex items-center gap-[6px] !mt-[6px]">
                <app-price-format [price]="item.net_price"
                    [styleClass]="'seo-price text-palette-orange-dark-500 text-base font-medium !leading-6'"></app-price-format>
                <app-price-format [price]="item.original_price"
                    [styleClass]="'seo-price text-palette-gray-500 text-sm whitespace-nowrap text-ellipsis overflow-hidden line-through'"></app-price-format>
            </div> -->

            <div class="flex flex-col items-end gap-[6px] !mt-[6px] min-h-[60px] justify-end">
                <div *ngIf="item.original_price !== item.net_price">
                    <app-price-format 
                        [price]="item.original_price"
                        [styleClass]="'seo-saling-price text-palette-gray-500 text-sm whitespace-nowrap text-ellipsis overflow-hidden line-through'">
                    </app-price-format>
                </div>
                <app-price-format
                    [price]="item.net_price"
                    [styleClass]="'seo-price text-palette-orange-dark-500 text-base font-medium !leading-6'">
                </app-price-format>
            </div>
        </div>
    </div>

    </a>
    <button
    class="btn-favorite flex justify-center !h-7 bg-palette-gray-900 bg-opacity-40 rounded-lg p-[6px] absolute top-2 right-2 text-center"
    (click)="handleBookmark($event)"
    >   
    <i
        class="!text-base !leading-4"
        [ngClass]="{ 'sctr-icon-solid-bookmark text-palette-amber-400': isBookmarked, 'text-white sctr-icon-bookmark': !isBookmarked }">
    </i>
    </button>
</div>
<app-toast keyToast="fh"></app-toast>