<ng-container *ngIf="loading; else popup">
    <div class="ota-custom lg:mx-[12.5%] md:mx-[8%] mx-[2%] flex justify-center font-inter property-agency-info mt-[80px]">
        <div class="flex item-center justify-center max-w-[1200px] h-[500px] w-full !pt-[100px]">
            <div class="progress-spinner">
                <p-progressSpinner></p-progressSpinner>
            </div>
        </div>
    </div>
</ng-container>
<ng-template #popup>
    <div class="fixed top-0 right-0 bottom-0 left-0 z-[9999] bg-palette-base-black bg-opacity-40 h-screen !overflow-hidden !overflow-y-hidden !overflow-x-hidden"
            (click)="closePopup()">
        <div class="popup-container flex flex-col items-start rounded-xl w-[486px] relative" (click)="stopPropagationEvent($event)">
            <img
                src="assets/icons/popup_recommend_signin/Stars1.svg"
                alt="Stars1"
                class="w-[93px] h-[59px] left-6 top-2 absolute"/>
            <img
                src="assets/icons/popup_recommend_signin/Stars2.svg"
                alt="Stars2"
                class="w-[43px] h-[58px] right-[91px] top-[7px] absolute"/>
            <img
                src="assets/icons/popup_recommend_signin/Circle.svg"
                alt="Circle"
                class="circle w-[48px] h-[49px] right-[11px] top-[106px] absolute"/>
            <div class="!p-6 !pb-8 !gap-4 flex flex-col items-center self-stretch rounded-[0px] w-full">        
                <div class="flex !p-6 flex-col items-center !gap-4 self-stretch rounded-lg bg-palette-base-white w-[438px] z-[10]">            
                    <p class="self-stretch text-center text-palette-gray-700 font-inter font-medium text-base">
                        {{'COMMON.FOWARD_ERROR' | translate}}</p>
                    <button class="flex !h-12 !py-3 !px-5 items-center justify-center gap-2 self-stretch rounded-lg border border-palette-blue-600 bg-palette-blue-600 hover:bg-palette-blue-500" (click)="closePopup()">
                        <span class="text-palette-base-white font-inter text-base font-semibold">
                            {{'COMMON.CLOSE' | translate}}
                        </span>
                    </button>
                </div>
            
            </div>
        </div>
    </div>
</ng-template>

