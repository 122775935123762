<div class="flex gap-2 items-center">
    <!-- Back button -->
    <div *ngIf="isShowBtn && (rotate || activeIndex > 0)"
        (click)="onBack()" 
        class="left-4 !w-5 !h-6 bg-white flex justify-center items-center rounded cursor-pointer hover:!bg-palette-gray-100">
        <i class="sctr-icon-chevron-left text-sm text-palette-gray-500"></i>
    </div>  
    
    <!-- Items Wrapper -->
    <div class="overflow-hidden w-full {{style}} tab_wrapper" [id]="'tab_wrapper_' + id" #tabWrapper>
        <div class="flex" [style.gap.px]="gap">
            <ng-container *ngFor="let item of items; let index = index">
                <div class="tab-item w-fit" 
                    [id]="id + '_tab_item_' + index"
                    [ngClass]="{ 'highlight-tab': index === activeIndex }">
                    <ng-container *ngIf="itemTemplate; else defaultTemplate"
                        [ngTemplateOutlet]="itemTemplate"
                        [ngTemplateOutletContext]="{ $implicit: item, index: index }">
                    </ng-container>
                </div>
            </ng-container>
        </div>
    </div>
    
    <!-- Next button -->
    <div *ngIf="isShowBtn && (rotate || activeIndex < (items.length-1))"
        (click)="onNext()" 
        class="!w-5 !h-6 bg-white flex justify-center items-center rounded cursor-pointer hover:!bg-palette-gray-100">
        <i class="sctr-icon-chevron-right text-sm text-palette-gray-500 "></i>
    </div>
</div>

<ng-template #defaultTemplate let-item>
    <div class="default-item">
        <p>{{ item }}</p>
    </div>
</ng-template>