import { Component, ElementRef, ViewChild } from '@angular/core';
import { BookingType } from '@modules/hotel-booking/models/booking-type.model';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { SearchBookingIDService } from '@modules/hotel-booking/services/search-booking-id.service';
import { TranslateService } from '@ngx-translate/core';
import { ReservationSearchService } from '@modules/hotel-booking/services/reservation-service/reservation-search.service';
import { firstValueFrom } from 'rxjs';
import { environment } from '@env/environment';
import { MY_BOOKING } from '@shared/constants/routes';
import {
  FlightReservationControllerService,
  ReservationControllerService,
  ReservationFlightRequestCodeDTO,
  ReservationRequestCodeDTO,
} from '@booking/angular-booking-reservation-service';
import { FlightReservationSearchService } from '@modules/hotel-booking/services/flight/flight-reservation-search/flight-reservation-search.service';

@Component({
  selector: 'app-booking-menu',
  templateUrl: './booking-menu.component.html',
  styleUrls: ['./booking-menu.component.scss'],
})
export class BookingMenuComponent {
  state = 'start';
  interval: any;

  bookingType: string = 'hotel';
  bookingTypeList: BookingType[] = [];
  visible: boolean = false;
  isLoading: boolean = false;

  // nav modal
  modalBookingType = 'hotel';
  modalBookingTypeList: BookingType[] = [];
  disableSearchButton: boolean = true;
  searchBookingId: string = '';
  PINcode: string = '';
  isShowPinCode: boolean = false;
  responseDataSearch: any = {};
  isSearchBooking: boolean = false;

  baseImageId = environment.STORAGE_SERVICE_API_BASE_PATH_ID_IMAGE;
  pipe = new DatePipe('en-US');
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

  constructor(
    private router: Router,
    private searchBookingIDService: SearchBookingIDService,
    private reservationController: ReservationControllerService,
    private translateService: TranslateService,
    private reservationSearchService: ReservationSearchService,
    private flightReservationControllerService: FlightReservationControllerService,
    private flightReservationSearchService: FlightReservationSearchService
  ) {
    let MENU_ITEM = ['flight', 'hotel', 'car-rental', 'tours'];
    const commonElements = this.router.url
      .split('/')
      .filter((element_url) => MENU_ITEM.includes(element_url));

    this.bookingType = commonElements ? commonElements[0] : 'hotel';

    this.bookingTypeList = [
      {
        id: '1',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        short_name: 'flight',
        text_color: 'text-palette-blue-600',
        icon: 'sctr-icon-plane',
      },
      {
        id: '2',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        short_name: 'hotel',
        text_color: 'text-palette-rose-600',
        icon: 'sctr-icon-building-01',
      },
      {
        id: '3',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        short_name: 'car-rental',
        text_color: 'text-palette-orange-600',
        icon: 'sctr-icon-car-01',
      },
      {
        id: '4',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        short_name: 'tours',
        text_color: 'text-palette-purple-600',
        icon: 'sctr-icon-flag-05',
      },
    ];

    this.modalBookingTypeList = [
      {
        id: '1',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.FLIGHTS',
        short_name: 'flight',
        icon: 'sctr-icon-plane',
        text_color: 'text-palette-blue-600',
        background_color: 'bg-palette-blue-50',
      },
      {
        id: '2',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.HOTELS',
        short_name: 'hotel',
        icon: 'sctr-icon-building-07',
        text_color: 'text-palette-rose-600',
        background_color: 'bg-palette-rose-50',
      },
      {
        id: '3',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.CAR_RENTALS',
        short_name: 'car-rental',
        icon: 'sctr-icon-car-01',
        text_color: 'text-palette-orange-600',
        background_color: 'bg-palette-orange-50',
      },
      {
        id: '4',
        booking_type_name: 'HEADER.MENU.BOOKING_MENU.TOURS',
        short_name: 'tours',
        icon: 'sctr-icon-flag-05',
        text_color: 'text-palette-purple-600',
        background_color: 'bg-palette-purple-50',
      },
    ];
  }

  showDialog() {
    this.onCloseModal(); // clear value before open popup
    this.visible = true;
    this.searchBookingIDService.setIsOpenModal(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  handleClickNav(menuItemName: BookingType) {
    switch (menuItemName.short_name) {
      case 'tours':
        return environment.TRAVEL_ASSIST_URL;
      case 'car-rental':
        return environment.CAR_URL;
      case 'flight':
        return environment.FLIGHT_URL;
      default: // Handle 'hotel' case
        return `/booking/${menuItemName.short_name}`;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.searchInput.nativeElement.value = '';
      this.searchInput.nativeElement.setAttribute(
        'autocomplete',
        'new-password'
      );
    });
  }

  onCloseModal() {
    this.PINcode = '';
    this.searchBookingId = '';
    this.responseDataSearch = {};
    this.isSearchBooking = false;
    this.searchBookingIDService.clearValue();
    this.disableSearchButton = true;
    this.searchBookingIDService.setIsOpenModal(false);
  }

  handleNavBookingType(typeBooking: string) {
    this.modalBookingType = typeBooking;
    const searchHistory = this.searchBookingIDService.getValue();

    const existingItemIndex = searchHistory.findIndex(
      (item) => item.type_booking === typeBooking
    );

    if (existingItemIndex === -1) {
      this.searchBookingId = '';
      this.PINcode = '';
      this.responseDataSearch = {};
      this.isSearchBooking = false;
    } else {
      this.searchBookingId = searchHistory[existingItemIndex].search_booking_id;
      this.PINcode = searchHistory[existingItemIndex].pin_code;
      this.responseDataSearch = {};
      this.isSearchBooking = false;
    }

    this.checkSearchButton();
  }

  checkSearchButton() {
    if (this.searchBookingId !== '' && this.PINcode !== '') {
      this.disableSearchButton = false;
    } else {
      this.disableSearchButton = true;
    }
  }

  isEmptyObject(obj: Record<string, any>): boolean {
    return Object.keys(obj).length === 0;
  }

  async searchHotel(reservationRequestDTO: ReservationRequestCodeDTO) {
    try {
      this.isLoading = true;
      const response = await firstValueFrom(
        this.reservationController.getHotelReservation(
          reservationRequestDTO,
          'body',
          true
        )
      );
      if (response.success) {
        this.responseDataSearch = {
          hotel_image_overview_url:
            response.data.property.property_image_overview_url,
          hotel_image_overview_id:
            response.data.property.property_image_overview_id,
          hotel_name: response.data.property.property_name,
          reservation_code: response.data.reservation_code,
          check_in: response.data.checkin_date,
          check_out: response.data.checkout_date,
          detail_link: response.data.qr_link
        };
        // this.reservationSearchService.setItemStorage({
        //   reservation_code: response.data.reservation_code,
        //   pin_code: response.data.pin_code,
        // });
        this.isLoading = false;
      } else {
        this.responseDataSearch = {};
        this.isLoading = false;
      }
    } catch (err) {
      console.error(err);
      this.responseDataSearch = {};
      this.isLoading = false;
    }
    this.isSearchBooking = true;
  }

  async searchFlight(reservationRequestDTO: ReservationFlightRequestCodeDTO) {
    // try {
    //   this.isLoading = true;
    //   const response = await firstValueFrom(
    //     this.flightReservationControllerService.searchReservationByReservationCodeAndPinCode(
    //       reservationRequestDTO,
    //       'body',
    //       true
    //     )
    //   );
    //   if (response.success) {
    //     this.responseDataSearch = response.data;
    //     // this.flightReservationSearchService.setItemStorage({
    //     //   reservation_code: response.data.reservation_code,
    //     //   pin_code: response.data.pin_code,
    //     // });
    //     this.isLoading = false;
    //   } else {
    //     this.responseDataSearch = {};
    //     this.isLoading = false;
    //   }
    // } catch (err) {
    //   console.error(err);
    //   this.responseDataSearch = {};
    //   this.isLoading = false;
    // }
    // this.isSearchBooking = true;
  }

  handleClickSearch() {
    if (this.modalBookingType === 'hotel') {
      const reservationRequestDTO: ReservationRequestCodeDTO = {
        reservation_code: this.searchBookingId,
        code: this.PINcode,
      };
      this.searchHotel(reservationRequestDTO);
    } else if (this.modalBookingType === 'flight') {
      const reservationRequestDTO: ReservationFlightRequestCodeDTO = {
        reservation_code: this.searchBookingId,
        pin_code: this.PINcode,
      };
      this.searchFlight(reservationRequestDTO);
    }
  }

  onSearchBookingIdInputChange(event: any) {
    this.checkSearchButton();
    this.searchBookingIDService.setValue({
      type_booking: this.modalBookingType,
      search_booking_id: this.searchBookingId || '',
      pin_code: this.PINcode || '',
    });
  }

  togglePassword() {
    this.isShowPinCode = !this.isShowPinCode;
  }

  handleNavigateBookingSearch(item: any) {
    window.scrollTo(0, 0);
    window.open(item.detail_link, '_blank');
    this.onCloseModal();
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/temporary/default-hotel.png';
  }
}
