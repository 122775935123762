import { Component, HostListener, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { FooterComponent } from '@layout/footer/footer.component';
import { HeaderBookingComponent } from '@layout/header/header.component';
import { AuthService } from './services/auth.service';
import { TokenStorageService } from './services/token-storage.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { PermissionService } from './core/services/permission/permission.service';
import { ButtonModule } from 'primeng/button';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { BookMarkService } from '@modules/hotel-booking/services/bookmark/bookmark.service';
import { SharedModule } from '@shared/shared.module';
import { SafeResourceUrl } from '@angular/platform-browser';
import { FooterService } from '@modules/hotel-booking/services/footer/footer.service';
import { Subject } from 'rxjs';
import { PrimeNGConfig } from 'primeng/api';
import { GeolocationService } from './services/geolocation/geolocation.service';
import { SoctripTranslationService } from './services/soctrip-translation/soctrip-translation.service';
import { SoctripChatModule } from '@soctrip-common/chat';
import { SocchatService } from '@modules/hotel-booking/services/socchat-sevice/socchat.service';
import { FeatureControlControllerService } from '@booking/angular-booking-generic-service';
import { DeviceService } from './core/services/device.service';
import { showDownloadPopup } from '@soctrip-common/route-transform-lib';
import { MulTranslationService } from './core/services/mul-translation/mul-translation.service';
import { BrowseAccount } from '@shared/enum/browse-account.enum';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    /* primeng */
    ButtonModule,
    /* @angular */
    RouterOutlet,
    /* @components */
    FooterComponent,
    HeaderBookingComponent,
    SharedModule,
    SoctripChatModule
  ],
  template: `
    <div class="root-component">
      <app-header />
      <app-toast></app-toast>
      <router-outlet></router-outlet>
      <app-popup-cta></app-popup-cta>
      <app-footer />
      <ng-container *ngIf="!isHiddenFloatChatBtn && userInfo">
        <div class="t-chat-popup" id="t-chat-popup">
          <lib-chat-popup
            class="t-chat-popup"
            [baseUrl]="baseURL" 
            [userInfo]="userInfo" 
            [module]="module"
            [isEnableChatBot]="true"
            [isDisableCloseDialogFromOutside]="true"
            [domain]="DOMAIN"
          ></lib-chat-popup>
        </div>
      </ng-container>
    </div>
  `,
})
export class AppComponent implements OnInit {
  //TODO: Replace with actual code
  loginForm = { password: '123456a@A', username: 'testaccount' };
  userRole: string[] = [];
  socialApp: string = environment.SOCIAL_APP_URL;
  socialAppConnectUrl: string = environment.SOCIAL_APP_URL + '/app-connect';
  socialAppSafeUrl: SafeResourceUrl;
  isLoading: boolean = true;
  translatedCalendar: any;
  private ngUnsubscribe = new Subject<void>();
  isHiddenFloatChatBtn: boolean = false;
  baseURL = environment.baseURL;
  userInfo = localStorage.getItem('user_profile') ? JSON.parse(localStorage.getItem('user_profile')!) : undefined;
  module: string = 'HOTEL';
  DOMAIN = environment.DOMAIN;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private tokenStorageService: TokenStorageService,
    private router: Router,
    private footerService: FooterService,
    private permissionService: PermissionService,
    private translateService: TranslateService,
    private bookMarkService: BookMarkService,
    private config: PrimeNGConfig,
    private geolocationService: GeolocationService,
    private soctripTranslationService: SoctripTranslationService,
    private socchatService: SocchatService,
    private featureControlControllerService :FeatureControlControllerService,
    private deviceService: DeviceService,
    private mulTranslationService: MulTranslationService,
  ) {
    this.bookMarkService.getBloomFilter();
    this.translateService.addLangs(environment.support_languages);
  
    // Open soctrip app when user uses mobile browser.
    // this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: any) => {
    //   if (event instanceof NavigationEnd && this.deviceService.isMobile()) {
    //     const currentUrl = window.location.href;
    //     transform(currentUrl, environment.baseURL).then((universalRoute: any) => {
    //       this.deviceService.tryOpenApp(universalRoute ?? environment.SOCIAL_APP_DEEPLINK);
    //     });
    //   }
    // });

    // this.userService
    //   .getUserProfile()
    //   .pipe(takeUntil(this.ngUnsubscribe))
    //   .subscribe((user) => {
    //     if (user) {
    //       if (
    //         user.language &&
    //         environment.support_languages.includes(user.language.toLowerCase())
    //       ) {
    //         const lang = user.language.toLowerCase();
    //         this.translateService.setDefaultLang(lang);
    //         localStorage.setItem('lang', lang);
    //       } else {
    //         this.translateService.setDefaultLang(
    //           environment.support_languages[0]
    //         );
    //         localStorage.setItem('lang', environment.support_languages[0]);
    //       }

    //     } else {
    //       localStorage.setItem('lang', environment.support_languages[0]);
    //       // localStorage.setItem('currency', environment.support_currency[0]);
    //       this.translateService.setDefaultLang(
    //         environment.support_languages[0]
    //       );
    //       this.setTranslateCalendar();
    //       // this.footerService.setCurrency({
    //       //   code: environment.support_currency[0],
    //       // });
    //     }
    //   });
  }

  ngOnInit(): void {
    localStorage.setItem('ignore-secure', '');
    localStorage.setItem('unauthLang', environment.languages[0]);
    localStorage.removeItem('lang');
    this.connectPMServer();
    this.checkPermission();
    this.setTranslateCalendar();
    this.setShowSocchat();
     // ****************************** APPLY FEATURE CONTROL ******************************
    this.getFeatureControlConfig();
     // ****************************** APPLY FEATURE CONTROL ******************************END
  }

  ngAfterViewInit(): void {
    if (this.deviceService.isMobile()) {
      let lang = environment.languages[0];
      this.translateService.addLangs(environment.languages);
      const browserLang = this.translateService.getBrowserLang() || lang;
      const selectedLang = this.mulTranslationService.getKeyLocalUserProfile('language')?.toLowerCase();
      if (selectedLang && environment.languages.includes(selectedLang)) {
        lang = selectedLang;
      } else if (!selectedLang && environment.languages.includes(browserLang)) {
        lang = localStorage.getItem('unauthLang') || (environment.languages.includes(browserLang) ? browserLang : lang);
      }
      const currentUrl = window.location.href;
      showDownloadPopup(lang, currentUrl, environment.baseURL);
    }
  }

  setShowSocchat(){
    this.socchatService.getFloatChatBtn().subscribe((data) => {
      this.isHiddenFloatChatBtn = !data || false;
    });
  }

  checkPermission(): void {
    this.geolocationService.checkGeolocationPermission().then((status) => {
      if (status !== 'granted') {
        this.getLocation();
      }
    });
  }

  getLocation(): void {
    this.geolocationService.requestLocation().then();
  }

  setTranslateCalendar() {
    this.translatedCalendar = {
      dayNames: this.translateService.instant('PRIMENG.DAY_NAMES'),
      dayNamesShort: this.translateService.instant('PRIMENG.DAY_NAMES_SHORT'),
      dayNamesMin: this.translateService.instant('PRIMENG.DAY_NAMES_MIN'),
      monthNames: this.translateService.instant('PRIMENG.MONTH_NAMES'),
      monthNamesShort: this.translateService.instant(
        'PRIMENG.MONTH_NAMES_SHORT'
      ),
      today: this.translateService.instant('PRIMENG.TODAY'),
      weekHeader: this.translateService.instant('PRIMENG.WEEK_HEADER'),
    };
    this.config.setTranslation(this.translatedCalendar);
  }

  connectPMServer() {
    const ifr = document.createElement('iframe');
    ifr.classList.add('hidden');
    document.body.appendChild(ifr);
    ifr.src = this.socialAppConnectUrl;
    const connect = () => {
      ifr.contentWindow?.postMessage({ type: 'connect' }, '*');
    };
    ifr.onload = connect;
  }

  @HostListener('window:message', ['$event'])
  receiveMessage(event: any) {
    console.warn('event', event);

    const permissionLocalStorage = localStorage.getItem(BrowseAccount.PERMISSION);
    const url = new URL(window.location.href);
    const permission = url.searchParams.get(BrowseAccount.PERMISSION);
    if (permissionLocalStorage == BrowseAccount.ADMIN || permission == BrowseAccount.ADMIN) {
      console.warn(permissionLocalStorage);
      return;
    }

    if (window.location.hostname !== 'localhost') {
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
      if (event.origin === this.socialApp) {
        if (
          event.data.type === 'authorized' ||
          event.data.type === 'unauthorized'
        ) {
          if (event.data.type === 'authorized' && event.data.token != null) {
            this.socialAppConnectUrl = '';
            this.tokenStorageService.saveToken(event.data.token.accessToken);
            const profile = JSON.parse(event.data.profile);
            localStorage.setItem(
              environment.USER_ROLE_KEY,
              JSON.stringify(['USER'])
            );
            const user = JSON.parse(
              localStorage.getItem(environment.USER_PROFILE) || '{}'
            );
            if (profile?.id != user.id) {
              this.userService
                .getUserInfoById(profile?.id)
                .subscribe((data) => {
                  if (data) {
                    this.userService.setUserInfo(data);
                    localStorage.setItem(
                      environment.USER_PROFILE,
                      JSON.stringify(data)
                    );
                    this.userService.setLoginStatus(true);
                  }
                });
            }
            this.userService.setLoginStatus(true);
            localStorage.setItem(
              'auth_status',
              JSON.stringify({ isLoggedIn: true })
            );
            localStorage.removeItem('unauthLang');
            localStorage.removeItem('is_admin');
            this.isLoading = false;
          } else {
            if (
              event.data.type === 'unauthorized' ||
              event.data.token === null
            ) {
              this.isLoading = false;
              localStorage.setItem('unauthLang', event.data.language);
              localStorage.setItem(
                'currency-conversation-data',
                event.data.currency
              );
              this.translateService.setDefaultLang(event.data.language);
              this.soctripTranslationService.setLanguage(event.data.language);
              localStorage.removeItem(environment.AUTH_STATUS);
              localStorage.removeItem(environment.TOKEN_KEY);
              localStorage.removeItem(environment.USER_PROFILE);
              localStorage.removeItem(environment.ACCESS_TOKEN_EXPIRY_KEY);
              localStorage.removeItem(environment.REFRESH_TOKEN_EXPIRY_KEY);
              localStorage.removeItem(environment.REFRESH_TOKEN_KEY);
              localStorage.removeItem(environment.USER_ROLE_KEY);
              this.userService.setUserProfile(null);
              this.userService.setUserInfo(null);
              this.userService.setLoginStatus(false);
              // window.location.href = environment.SOCIAL_APP_URL + '/login';
            }
          }
        }
      }
    } else {
      this.isLoading = false;
    }
  }

  login() {
    this.authService.login(this.loginForm).subscribe((res: any) => {
      this.tokenStorageService.saveToken(res.accessToken);
      this.tokenStorageService.saveRefreshToken(res.refreshToken);
      this.tokenStorageService.saveAccessTokenExpiry(
        //TODO: Replace with actual code
        // res.access_token_expiry_date
        '2023-07-17 10:14:55'
      );
      this.tokenStorageService.saveRefreshTokenExpiry(
        //TODO: Replace with actual code
        // res.refresh_token_expiry_date
        '2023-07-30 17:50:55'
      );

      //TODO: Replace with actual code
      // userID (temporary)
      const userId = '123456';
      this.userService.getUserInfo(userId).subscribe((res: any) => {
        //TODO: Replace with actual code
        this.userRole = res;
        this.userService.saveUserInfoToLocalStorage(this.userRole);
      });
      this.permissionService.userRoles.next(this.userRole);
      this.router.navigate(['/users']);
    });
  }

  logout() {
    this.tokenStorageService.clearTokenLocalStorage();
    this.userService.clearUserLocalStorage();
    this.permissionService.userRoles.next(['']);

    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  //TODO: Replace with actual code
  getUserInfo() {
    this.authService.getUserInfo().subscribe();
    //TODO: Replace with actual code
    // localStorage.clear();
    this.router.navigate(['/auth/login']);
  }

  test(a: number, b: number): number {
    return a + b;
  }

 // ****************************** APPLY FEATURE CONTROL ******************************
  getFeatureControlConfig(){
    const currentDataJSON = localStorage.getItem("featureControl") || '{}';
    const currentData = JSON.parse(currentDataJSON);

   if (currentData.expiry - Date.now() < 0 || Object.keys(currentData).length === 0) {
    this.featureControlControllerService.getFeatureControlByRelease(environment.RELEASE_VERSION,"HOTEL").subscribe({
      next: (response:any) => {
        if (response.success) {
          let featureControlData = response.data;
          let featureList:any = {};
          featureList.expiry = Date.now() + 300000;
          featureList.features = [];
          featureControlData.forEach((feature: any) => {
            let featureTemp: any = {};
            featureTemp.feature_code = feature.feature_code;
            featureTemp.status = feature.status;
            featureTemp.configs = feature.configs;
            featureList.features.push(featureTemp);
          });
          localStorage.setItem(
            "featureControl",
            JSON.stringify(featureList)
          );          
        }
      },error: (err:any) => {
        console.log(err);
      }
    });
   } else {
    console.log("error");  
   }
  }
   // ****************************** APPLY FEATURE CONTROL ******************************END

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
