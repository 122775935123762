import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { UserService } from 'src/app/core/services/user/user.service';
import { Router } from '@angular/router';
import { createSlug, parseJwt } from '@shared/utils/helper';
import { TranslateModule } from '@ngx-translate/core';
import { environment } from '@env/environment';
import { BrowseAccount } from '@shared/enum/browse-account.enum';

@Component({
  selector: 'app-admin-support-agency',
  standalone: true,
  imports: [
    CommonModule,
    ProgressSpinnerModule,
    TranslateModule
  ],
  templateUrl: './admin-support-agency.component.html',
  styleUrls: ['./admin-support-agency.component.scss']
})
export class AdminSupportAgencyComponent {
  loading: boolean = true;
  timeoutId: any;

  constructor(    
    private userService: UserService,
    private router: Router,
  ) {
    this.timeoutId = setTimeout(() => {
      this.loading = false;
    }, 10000);
  }

  ngOnInit() {
    const url = new URL(window.location.href);
    const permission = url.searchParams.get(BrowseAccount.PERMISSION);
    const adminToken = url.searchParams.get(BrowseAccount.ADMIN_TOKEN);
    if(permission == BrowseAccount.ADMIN) {
      console.warn(permission);
      const agencyData = parseJwt(adminToken || '');
      if (agencyData) {
        const userObject = parseJwt(agencyData.accessToken);
        this.userService
        .getUserInfoById(userObject.user_id)
        .subscribe((data) => {
          if (data) {
            this.userService.setUserInfo(data);
            localStorage.setItem(
              environment.USER_PROFILE,
              JSON.stringify(data)
            );
            this.userService.setLoginStatus(true);
  
            localStorage.setItem(BrowseAccount.PERMISSION, BrowseAccount.ADMIN);
            localStorage.setItem('access_token', agencyData.accessToken);
            localStorage.setItem('user_role', JSON.stringify([BrowseAccount.USER]));
            localStorage.setItem('agency_id', agencyData.agencyId);
            localStorage.setItem('agency_name', agencyData.agencyName);
  
            const type = agencyData.type
            const propertyId = agencyData.propertyId
            const propertyName = agencyData.propertyName
  
            if (type == BrowseAccount.AGENCY) {
              this.router.navigate(['/ota/hotel-ota']);
            } else if (type == BrowseAccount.PROPERTY) {
              this.router.navigate(
                [`ota/hotel-ota/hotel-management/${createSlug(propertyName)}/property-management/general-info`], 
                {
                  queryParams: {
                    property_id: propertyId,
                    property_name: createSlug(propertyName)
                  }
                }
              )
            } else {
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        });
      } else {
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  ngOnDestroy() {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }

  closePopup(): void {
    window.close();
  }

  stopPropagationEvent(event: Event): void {
    event.stopPropagation();
  }
}
