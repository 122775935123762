import { Injectable } from '@angular/core';
import { BookmarkBasicDTO, BookmarkControllerService } from '@soctrip/angular-bookmarks-service';
import { BehaviorSubject, Observable, catchError, map, of, switchMap } from 'rxjs';
import { LocalStorageService } from 'src/app/services/local-storage/local-storage.service';
import { environment } from '@env/environment';

const { BloomFilter } = require('@soctrip-common/soctrip-algorithm/dist/api.js');

@Injectable({
    providedIn: 'root'
})
export class BookMarkService {
    bloomFilter: string | null = null;
    isBloomFilterLoaded$ = new BehaviorSubject<boolean>(false);
    isFirstLoaded: boolean = true;
    recentSavedList$ = new BehaviorSubject<any>(null);


    constructor(
        private bookmarkControllerService: BookmarkControllerService,
        private localStorageService: LocalStorageService,
    ) {}

    setIsBloomFilterLoaded(isLoaded: boolean) {
        this.isBloomFilterLoaded$.next(isLoaded);
    }

    getIsBloomFilterLoaded() {
        return this.isBloomFilterLoaded$.asObservable();
    }

    getBloomFilter(): void {
        this.bookmarkControllerService.bookmarksBloomFilterGet().subscribe({
            next: (response) => {
                if(response.success) {
                    this.localStorageService.setItem(environment.BLOOM_FILTER, response.data.bloom_filter);
                    if(this.isFirstLoaded) this.setIsBloomFilterLoaded(true);
                    this.isFirstLoaded = false;
                }
            }
        });
    }

    addBookMark(id: string): Observable<any> {
        let bookMarkBasicDTO: BookmarkBasicDTO = {
            object_id: id,
            dag_object: 'HOTEL_BOOKMARK'
        }
        return this.bookmarkControllerService.bookmarksPost(bookMarkBasicDTO);
    }

    getSavedListHotels(pageNum: number, pageSize: number): Observable<any> {
        let filter = 'object_sub_category==21';
        return this.bookmarkControllerService.bookmarksSearchGet(pageNum, pageSize, filter);
    }

    removeBookMark(id: string): Observable<any> {
        return this.bookmarkControllerService.bookmarksObjectIdDelete(id);
    }

    isBookmarkExists(id: string): Observable<boolean> {
        return this.getIsBloomFilterLoaded().pipe(
            switchMap((isLoaded: boolean) => {
                if (isLoaded) {
                    let bloomFilterString = this.localStorageService.getItem(environment.BLOOM_FILTER);
                    if(!bloomFilterString) return of(false);
                    let bloomFilter = BloomFilter.load(bloomFilterString);
                    const exists = bloomFilter.has(id);
                    if(exists) {
                        return this.bookmarkControllerService.bookmarksObjectIdIsExistedGet(id).pipe(
                            map((response) => {
                                if (response.success && response.data.is_existed) {
                                    return response.data.is_existed;
                                }
                                return false;
                            }),
                            catchError(error => {
                                return of(false);
                            })
                        );
                    }
                    return of(false);
                } else {
                    return of(false);
                }
            })
        );
    }
}