import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-tab',
  templateUrl: './tab.component.html',
  styleUrls: ['./tab.component.scss']
})
export class TabComponent {
  @Input() items: any[] = [];
  @Input() rotate: boolean = false;
  @Input() gap: number = 0;
  @Input() itemTemplate?: TemplateRef<any>;
  @Input() style: string = '';
  @Input() id: string = '';
  @Input() activeIndex = 0;
  @Output() indexSelected = new EventEmitter();

  @ViewChild('tabWrapper') tabWrapper!: ElementRef;

  isShowBtn: boolean = true;

  constructor(private cdr: ChangeDetectorRef) { }

  ngOnInit() {
    this.checkShowBtn() 
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activeIndex']) {
      this.scrollImage();
      this.checkShowBtn();
    }
    if (changes['items']) {
      setTimeout(()=> {
        this.checkShowBtn();
      }, 300);
    }
  }

  ngAfterViewInit(){
    this.checkShowBtn() 
  }

  // Navigate to the previous item
  onBack() {
    this.activeIndex--;
    this.scrollImage();
    let selectedTab = {
      index: this.activeIndex,
      value: this.items[this.activeIndex]
    }
    this.indexSelected.emit(selectedTab);
  }

  // Navigate to the next item
  onNext() {
    this.activeIndex++;
    this.scrollImage();
    let selectedTab = {
      index: this.activeIndex,
      value: this.items[this.activeIndex]
    }
    this.indexSelected.emit(selectedTab);
  }

  scrollImage() {
    const tixPos = document.querySelector(`#${this.id}_tab_item_${this.activeIndex}`);
    if (tixPos) {
      tixPos.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'center',
      });
    }
  }

  checkShowBtn() {
    const nativeElement = this.tabWrapper?.nativeElement;
    if(nativeElement) {
      if(nativeElement.scrollWidth > nativeElement.clientWidth) {
        this.isShowBtn = true;
      } else {
        this.isShowBtn = false;
      }
    }
  }
}

