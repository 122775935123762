import { Component, OnInit, ViewChild } from '@angular/core';
import { environment } from '@env/environment';
import { CalendarDashboardService } from '@modules/ota/hotel-ota/services/calendar/calendar-dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { PrimeNGConfig } from 'primeng/api';
import { OverlayPanel } from 'primeng/overlaypanel';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  DateFilterType,
  DashboardDateFilterSection1,
  DashboardDateFilterSection2,
} from 'src/app/core/enum/date-filter.enum';

@Component({
  selector: 'app-calendar-dashboard',
  templateUrl: './calendar-dashboard.component.html',
  styleUrls: ['./calendar-dashboard.component.scss'],
})
export class CalendarDashboardComponent implements OnInit {
  protected readonly SoctripIcons = SoctripIcons;
  @ViewChild('calendarDashBoard') calendarPanel: OverlayPanel;
  rangeDates: (Date | undefined)[] = [];
  date: Date | undefined;
  dateFormat: string = 'DD/MM/YYYY';
  viewDate: any;
  selectionMode: string;
  currentDate = new Date();
  currentYear: number = new Date().getFullYear();
  currentLanguage: string = 'en';
  DFActive: DateFilterType;
  DF1 = DashboardDateFilterSection1;
  DF2 = DashboardDateFilterSection2;
  year = DateFilterType.YEAR;
  yearRange: Date[] = []
  selectYear: number;

  constructor(
    private calendarDashboardService: CalendarDashboardService,
    private config: PrimeNGConfig,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.initDefaultValue();
    this.initYearRange();
    this.filterDate({ value: DateFilterType.LAST_SIX_MONTHS }); // Default filter
    this.currentLanguage =
      localStorage.getItem('lang') || environment.support_languages[0]; // Apply current language for date picker
    // this.translateDatePicker(this.currentLanguage);
    this.selectYear = new Date().getFullYear();
  }

  get dateString() {
    return this.selectionMode === 'single'
      ? moment(this.date).format(this.dateFormat)
      : [
          this.rangeDates[0]
            ? moment(this.rangeDates[0]).format('DD/MM/YYYY')
            : '',
          this.rangeDates[1]
            ? moment(this.rangeDates[1]).format('DD/MM/YYYY')
            : '',
        ]
          .filter((item) => item)
          .join(' - ');
  }

  initDefaultValue() {
    this.viewDate = 'date';
    this.selectionMode = 'single';
    this.dateFormat = 'DD/MM/YYYY';
  }

  translateDatePicker(lang: string) {
    this.translateService.use(lang);
    this.translateService.get('primeng').subscribe((res) => {
      this.config.setTranslation(res);
    });
  }

  filterDate(item: { value: DateFilterType }) {
    this.initDefaultValue(); // reset state
    let date1, date2: Date | undefined;
    const currentDate = new Date();

    switch (item.value) {
      case DateFilterType.DAY:
      case DateFilterType.TODAY:
        date1 = new Date();
        break;
      case DateFilterType.YESTERDAY:
        const yesterday = new Date(currentDate);
        yesterday.setDate(currentDate.getDate() - 1);
        date1 = new Date(yesterday);
        break;
      case DateFilterType.LAST_THREE_MONTHS:
        this.selectionMode = 'range';
        const endLast3Month = moment()
          .subtract(2, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        const startLastMonth = moment()
          .subtract(0, 'months')
          .endOf('month')
          .format('YYYY-MM-DD');
        date1 = new Date(endLast3Month);
        date2 = new Date(startLastMonth);
        break;
      case DateFilterType.LAST_SIX_MONTHS:
        this.selectionMode = 'range';
        const endLast6Month = moment()
          .subtract(5, 'months')
          .startOf('month')
          .format('YYYY-MM-DD');
        const startLastMonth1 = moment()
          .subtract(0, 'months')
          .endOf('month')
          .format('YYYY-MM-DD');
        date1 = new Date(endLast6Month);
        date2 = new Date(startLastMonth1);
        break;
      case DateFilterType.WEEK:
        this.selectionMode = 'range';
        date1 = moment(currentDate).startOf('isoWeek').toDate();
        date2 = moment(currentDate).endOf('isoWeek').toDate();
        break;
      case DateFilterType.MONTH:
        this.viewDate = 'month';
        this.dateFormat = 'MM/YYYY';
        date1 = moment(currentDate).startOf('month').toDate();
        date2 = moment(currentDate).endOf('month').toDate();
        break;
      case DateFilterType.YEAR:
        this.viewDate = 'year';
        this.dateFormat = 'YYYY';
        date1 = moment(currentDate).startOf('year').toDate();
        date2 = moment(currentDate).endOf('year').toDate();
        break;
      default:
        break;
    }
    this.date = date1;
    this.rangeDates = [date1, date2];
    this.DFActive = item.value;
    this.settingDataFilter(date1, date2);
  }

  onSelect(event: any): void {
    this.initDefaultValue(); // reset state
    const givenDay = Array.isArray(event) ? moment(event[0]) : moment(event);
    let startDate: Date | undefined = this.date;
    let endDate: Date | undefined;
    switch (this.DFActive) {
      case DateFilterType.TODAY:
      case DateFilterType.YESTERDAY:
      case DateFilterType.LAST_THREE_MONTHS:
      case DateFilterType.LAST_SIX_MONTHS:
      case DateFilterType.DAY:
        this.DFActive = DateFilterType.DAY;
        this.date = givenDay.toDate();
        startDate = givenDay.toDate();
        endDate = givenDay.toDate();
        break;
      case DateFilterType.WEEK:
        this.selectionMode = 'range';
        startDate = givenDay.startOf('isoWeek').toDate();
        endDate = givenDay.endOf('isoWeek').toDate();
        this.rangeDates = [startDate, endDate];
        break;
      case DateFilterType.MONTH:
        this.dateFormat = 'MM/YYYY';
        this.viewDate = 'month';
        startDate = givenDay.startOf('month').toDate();
        endDate = givenDay.endOf('month').toDate();
        break;
      case DateFilterType.YEAR:
        this.dateFormat = 'YYYY';
        this.viewDate = 'year';
        startDate = givenDay.startOf('year').toDate();
        endDate = givenDay.endOf('year').toDate();
        break;
    }
    this.settingDataFilter(startDate, endDate);
  }

  settingDataFilter(startDate: any, endDate: any) {
    switch (this.DFActive) {
      case DateFilterType.TODAY:
      case DateFilterType.YESTERDAY:
      case DateFilterType.DAY:
        this.calendarDashboardService.setCalendarValue({
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate:
            this.DFActive === DateFilterType.YESTERDAY
              ? moment(startDate).format('YYYY-MM-DD')
              : moment(endDate).format('YYYY-MM-DD'),
          filter: 'DATE',
        });
        break;
      case DateFilterType.LAST_THREE_MONTHS:
      case DateFilterType.LAST_SIX_MONTHS:
        this.calendarDashboardService.setCalendarValue({
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          filter: 'MONTH',
        });
        break;
      case DateFilterType.WEEK:
      case DateFilterType.MONTH:
      case DateFilterType.YEAR:
        this.calendarDashboardService.setCalendarValue({
          startDate: moment(startDate).format('YYYY-MM-DD'),
          endDate: moment(endDate).format('YYYY-MM-DD'),
          filter: this.DFActive.toUpperCase(),
        });
        break;
      default:
        break;
    }
  }

  initYearRange(){
    this.yearRange = this.generateDateRange(2010, this.currentYear);
  }

  generateDateRange = (startYear: number, endYear: number): Date[] => {
    const dates: Date[] = [];
    for (let year = startYear; year <= endYear; year++) {
        dates.push(new Date(year, 0, 1)); // Thêm ngày 1/1 của mỗi năm
    }
    return dates;
  };

  onSelectYear(item: Date){
    this.date = item;
    this.selectYear = item.getFullYear();
    this.onSelect(item);
  }

}
