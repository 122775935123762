<div class="calendar-dashboard-container md:w-[280px]">
    <span class="w-full p-input-icon-right" (click)="calendarDashBoard.toggle($event)">
        <input pInputText class="h-9 min-h-0 truncate" placeholder="Select date" type="text" readonly
            [value]="dateString" />
        <i class="sctr-icon-calendar "></i>
    </span>
    <p-overlayPanel #calendarDashBoard styleClass="min-w-[453px] min-[1850px]:-ml-[173px]">
        <ng-template pTemplate="body">
            <div class="flex max-w-[453px] gap-4 px-2" (click)="$event.stopPropagation()">
                <div class="my-2 gap-2">
                    <ul class="flex flex-col">
                        <li class="cursor-pointer truncate rounded p-2 text-sm font-medium hover:bg-palette-gray-100"
                            *ngFor="let item of DF1" [ngClass]="
                DFActive === item.value
                  ? 'bg-palette-blue-50 text-palette-blue-600'
                  : 'hover:text-palette-blue-500'
              " (click)="filterDate(item)">
                            {{ "primeng." + item.value | translate | sentenceCase }}
                        </li>
                    </ul>
                    <hr />
                    <ul class="flex flex-col">
                        <li class="flex cursor-pointer items-center justify-between truncate rounded p-2 text-sm font-medium hover:bg-palette-gray-100"
                            *ngFor="let item of DF2" [ngClass]="
                DFActive === item.value
                  ? 'bg-palette-blue-50 text-palette-blue-600'
                  : 'hover:text-palette-blue-500'
              " (click)="filterDate(item)">
                            {{ "COMMON.BY" | translate | sentenceCase }}
                            {{ "primeng." + item.value | translate | sentenceCase }}
                            <i class="sctr-icon-chevron-right"></i>
                        </li>
                    </ul>
                </div>
                <div class="border-l w-full">
                    <ng-container *ngIf="DFActive == year; else otherTemplate">
                        <div class="flex flex-col !p-2">
                            <div class="flex w-full justify-between items-center max-w-full h-[65px] !p-2 !border-b border-[#dee2e6]">
                                <div class="w-[32px] flex items-center justify-center p-datepicker-prev-icon pi pi-chevron-left"></div>
                                <p class="text-base font-medium">2010 - {{currentYear}}</p>
                                <div class="w-[32px] flex items-center justify-center p-datepicker-next-icon pi pi-chevron-right"></div>
                            </div>
                            <div class="!m-0 !my-2 grid grid-cols-4">
                                <div *ngFor="let item of yearRange; let i = index" class="flex items-center justify-center">
                                    <p class="col-span-1 mx-1 my-2 p-2 border border-white rounded text-sm font-normal leading-3 cursor-pointer
                                        hover:bg-palette-gray-300"
                                        [ngClass]="{'!border-palette-blue-600  text-palette-blue-600': item.getFullYear() == selectYear}"
                                        (click)="onSelectYear(item)">
                                        <span class="px-2">{{item.getFullYear()}}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-template #otherTemplate>
                        <p-calendar class="calendar-dashboard" panelStyleClass="!border-0 w-full h-full"
                            [selectionMode]="selectionMode" [maxDate]="currentDate" [view]="viewDate"
                            yearRange="1990:{{ currentYear }}" [(ngModel)]="selectionMode == 'range' ? rangeDates : date"
                            (ngModelChange)="onSelect($event)" [inline]="true" [showOnFocus]="false"></p-calendar>
                    </ng-template>
                </div>
            </div>
        </ng-template>
    </p-overlayPanel>
</div>