import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { url, propertySearchRoom } from './config/app-config';
import { UserService } from 'src/app/core/services/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class SearchBarRoomService {
  private _queryParams: Subject<any> = new Subject();
  private queryParams$: Observable<any> = this._queryParams.asObservable();
  private _isRecallAPISearchRoom: Subject<any> = new Subject();
  private isRecallAPISearchRoom$: Observable<any> = this._isRecallAPISearchRoom.asObservable();

  private historySearch: any[];
  constructor(private http: HttpClient, private userService: UserService) {}

  propertySearchRoom(paramsInput?: any, idHotel?: string): Observable<any> {
    const params = new HttpParams({ fromObject: paramsInput as any });
    const body = { user_id: this.userService.getUserUuid() };
    return this.http.post<any>(
      `${url}${propertySearchRoom.propertySearchUrl}/${idHotel}/rooms`, body , { params }
    );
  }

  setQueryParamData(data: any) {
    this._queryParams.next(data);
  }
  
  getQueryParamData() {
    return this.queryParams$;
  }

  setIsRecallAPISearchRoom(isRecallAPISearchRoom: boolean) {
    this._isRecallAPISearchRoom.next(isRecallAPISearchRoom);
  }
  
  getIsRecallAPISearchRoom() {
    return this.isRecallAPISearchRoom$;
  }
}
