<div class="hotel-page-room-type block md:flex !gap-4 !mt-2 bg-palette-base-white border border-[#fff] rounded-lg">
    <div class="flex md:flex-col !gap-3  w-full md:!w-[258px]">
        <div>
            <div>
                <div *ngIf="item.room_image_overall_id; then imageID; else notImageID"></div>
                <ng-template #imageID>
                    <img class="!w-[120px] !h-[120px] md:!w-[258px] md:!h-[193px] object-cover rounded-lg bg-palette-gray-200"
                        src="{{baseUrl}}/storage/files/web/{{item.room_image_overall_id}}.webp"
                        (error)="replaceWithDefaultImage($event)" alt="">
                </ng-template>
                <ng-template #notImageID>
                    <div *ngIf="item.room_image_overall_url; then imageURL; else notImage"></div>
                    <ng-template #imageURL>
                        <img class="!w-[120px] !h-[120px] md:!w-[258px] md:!h-[193px] object-cover rounded-lg bg-palette-gray-200"
                            src={{item.room_image_overall_url}} (error)="replaceWithDefaultImage($event)" alt="">
                    </ng-template>
                    <ng-template #notImage>
                        <img class="!w-[120px] !h-[120px] md:!w-[258px] md:!h-[193px] object-cover rounded-lg"
                            src="assets/images/rooms/default_room.png" alt="">
                    </ng-template>
                </ng-template>
            </div>
            <div class="w-full my-3 !py-2 !px-4 h-[36px] text-sm font-inter font-semibold text-palette-gray-900 border border-palette-gray-300 rounded-lg flex justify-center items-center text-center cursor-pointer whitespace-nowrap hover:!bg-palette-gray-50"
                (click)="handleClickRoomHotel(item)">
                {{'DETAIL.VIEW_DETAIL' | translate}}
            </div>
        </div>


        <div class="grid grid-cols-2 !gap-y-2 !m-0 !gap-x-1">
            <div *ngIf="item.room_size" class="flex items-start lg:items-center !gap-1">
                <i class="sctr-icon-expand-03 text-palette-gray-500 text-[16px]"></i>
                <p *ngIf="item.room_size_unit.toLowerCase() !== 'square meters' && item.room_size_unit.toLowerCase() !== 'square feet'"
                    class="text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                    {{item.room_size}}{{item.room_size_unit}}</p>

                <p *ngIf="item.room_size_unit.toLowerCase() === 'square feet'"
                    class="text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                    {{item.room_size}}<span>ft²</span></p>

                <p *ngIf="item.room_size_unit.toLowerCase() === 'square meters'"
                    class="text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                    {{item.room_size}}<span>m²</span></p>
            </div>
            <div *ngIf="item.is_balcony" class="flex items-center !gap-1">
                <i class="sctr-icon-perspective-02 text-palette-gray-500 text-[16px]"></i>
                <p
                    class="text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                    Balcony</p>
            </div>
            <div *ngIf="item.room_view && item.room_view.room_view_name !== 'undefined'"
                class="flex items-center !gap-1">
                <i class="sctr-icon-{{item.room_view?.room_view_icon}} text-palette-gray-500 text-[16px]"></i>
                <p
                    class="text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                    {{item.room_view?.room_view_name}}</p>
            </div>
            <div class="w-full" *ngFor="let amenity of item.room_amenity_list?.slice(0, 8)">
                <div class="flex items-center !gap-1 relative">
                    <i class="sctr-icon-{{amenity.amenity_icon}} text-[16px] text-palette-gray-500"></i>
                    <span [tooltip]="amenity.amenity_name" [isTruncated]="true" [position]="TooltipPosition.RIGHT"
                        class="line-clamp-1! text-sm font-normal text-palette-gray-700 w-full whitespace-nowrap overflow-hidden text-ellipsis">
                        {{amenity.amenity_name}}
                    </span>
                </div>
            </div>
        </div>
    </div>
    <div class="flex-1">
        <div class="flex flex-col !gap-2 ">
            <div *ngIf="item.bed_types" class="flex !gap-4 text-sm font-medium ota-custom w-full my-2 md:my-0">
                <div class="flex !gap-1 items-start md:items-center whitespace-nowrap min-w-[105px] md:min-w-auto ">
                    <i class="sctr-icon-bed-3 text-base leading-5 text-palette-gray-500"></i>
                    <h4 class="text-palette-gray-500 font-medium">{{'DETAIL.BED_TYPE' | translate}}:</h4>
                </div>

                <ng-container *ngIf="item.bed_types.length > 1;  else oneBedType">
                    <div class="flex !gap-4 flex-wrap items-start">
                        <div *ngFor="let bedType of item.bed_types"
                            class="custom-input flex items-center w-fit relative">
                            <p-radioButton [inputId]="bedType.bed_id + '-' + index" (onClick)="onChooseBedType(item)"
                                name="bedType" [value]="bedType" [(ngModel)]="selectedBedType"
                                [ngClass]="!selectedBedType && isClickedBook ? 'is-invalid' : '' ">
                            </p-radioButton>
                            <div class="ml-2 w-full whitespace-nowrap overflow-hidden text-ellipsis" #bedType
                                (mouseover)="mouseOverBedType()">
                                <span *ngFor="let item of bedType.bed_type; let last = last">
                                    <label [for]="bedType.bed_id + '-' + index"
                                        class="text-palette-gray-700">{{item.num_of_beds}} {{ item.bed_type_name
                                        }}</label>
                                    <span *ngIf="!last"> + </span>
                                </span>
                            </div>
                            <div class="tooltip-bedtype absolute top-0 right-0 hidden cursor-default text-center z-100 transition-opacity duration-200 ease-in !px-3 !py-2
                                text-xs font-semibold text-palette-gray-700 bg-white rounded-lg whitespace-nowrap">
                                <span *ngFor="let item of bedType.bed_type; let last = last">
                                    <label [for]="bedType.bed_type_id + '-' + index"
                                        class="ml-2 text-palette-gray-700">{{item.num_of_beds}} x {{
                                        item.bed_type_name }}</label>
                                    <span *ngIf="!last">, </span>
                                </span>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-template #oneBedType>
                    <div class="flex items-center">
                        <span class="!w-4 !h-4"><i class="sctr-icon-bed-3 text-base leading-4"></i></span>
                        <span *ngFor="let bedType of item.bed_types[0]?.bed_type; let last = last">
                            <label class="ml-2 text-palette-gray-700">{{bedType.num_of_beds}} {{bedType.bed_type_name
                                }}</label>
                            <span *ngIf="!last"> + </span>
                        </span>
                    </div>
                </ng-template>
            </div>
            <div *ngFor="let rate_plan of item.rate_plan">
                <div
                    class="w-full !min-h-[138px] h-auto bg-palette-gray-50 border md:border-0 border-palette-gray-200 !p-4 rounded-lg block md:flex items-start font-inter md:!gap-2 relative {{rate_plan.best_match ? 'best-match' : ''}}">
                    <div *ngIf="rate_plan.best_match"
                        style="background: linear-gradient(263.89deg, #FF692E 0%, #FF4405 100%);"
                        class="absolute bottom-0 left-0 w-[81px] h-[26px] rounded-tr-md rounded-bl-md text-xs !leading-[18px] font-medium text-white flex justify-center items-center">
                        {{ 'DETAIL.BEST_MATCH' | translate }}
                    </div>
                    <!-- Included -->
                    <div [ngClass]="(checkDisplayTaxesAndCharge(rate_plan) || rate_plan?.max_adult_per_room != null) ? 'w-full md:w-1/4':'w-full md:w-1/3'"
                        class="min-h-fit md:min-h-[106px] !px-2 pb-1 md:py-0 h-full flex flex-col !gap-1 border-b md:border-b-0 md:border-r border-dashed border-palette-gray-200">
                        <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.INCLUDES' | translate}}
                        </p>
                        <div class="flex flex-col !gap-1">
                            <div *ngIf="rate_plan.meal_plan?.meal_plan_name"
                                class="flex items-center !gap-1 relative px-[6px] py-[2px] rounded-lg bg-palette-emerald-50 text-palette-emerald-600 max-w-fit">
                                <img src="assets/icons/hotel-amenities/fork_knife_icon.svg" alt="">
                                <span *ngIf="rate_plan.meal_plan"
                                    [tooltip]="isBedAndBreakfast(rate_plan.meal_plan.meal_plan_name) ? ('COMMON.BED_AND_BREAKFAST' | translate) : ('DETAIL.' + formatToTranslateMeal(rate_plan.meal_plan.meal_plan_name) | translate)"
                                    [isTruncated]="true" [position]="TooltipPosition.BELOW"
                                    class="line-clamp-1! font-inter text-xs font-medium h-auto overflow-hidden text-ellipsis whitespace-nowrap">
                                    {{ isBedAndBreakfast(rate_plan.meal_plan.meal_plan_name) ?
                                    ('COMMON.BED_AND_BREAKFAST' | translate) : ('DETAIL.' +
                                    formatToTranslateMeal(rate_plan.meal_plan.meal_plan_name) | translate) }}
                                </span>
                            </div>
                            <div *ngIf="rate_plan?.inclusions && rate_plan?.inclusions?.length !== 0"
                                class="flex items-center !gap-1 relative px-[6px] py-[2px] rounded-lg bg-palette-blue-50 text-palette-blue-600 max-w-fit">
                                <i class="sctr-icon-car-01 text-[16px]"></i>
                                <span [tooltip]="translateInclusions(rate_plan?.inclusions[0]) | translate"
                                    [isTruncated]="true" [position]="TooltipPosition.BELOW"
                                    class="line-clamp-1! font-inter text-xs font-medium overflow-hidden text-ellipsis whitespace-nowrap">
                                    {{ translateInclusions(rate_plan?.inclusions[0]) | translate }}
                                </span>
                            </div>
                            <div *ngIf="rate_plan?.inclusions?.length > 1"
                                class="relative rounded-lg bg-palette-blue-50 text-palette-blue-600 cursor-pointer max-w-fit">
                                <div [tooltip]="renderTooltipInclusion(rate_plan?.inclusions)" [isTruncated]="false"
                                    [position]="TooltipPosition.BELOW"
                                    class="px-[6px] py-[2px] font-inter text-xs font-medium overflow-hidden text-ellipsis whitespace-nowrap">
                                    {{renderTextInclusions(rate_plan)}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Cancellation policy -->
                    <div [ngClass]="(checkDisplayTaxesAndCharge(rate_plan) || rate_plan?.max_adult_per_room != null) ? 'w-full md:w-1/4':'w-full md:w-1/3'"
                        class="min-h-fit md:min-h-[106px] !px-2 py-1 md:py-0 border-b md:border-b-0 md:border-r border-dashed border-palette-gray-200 h-full flex flex-col !gap-4">
                        <div class="flex flex-col !gap-1">
                            <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.CANCELLATION' |
                                translate}}</p>
                            <div class="flex items-center !gap-2">
                                <div *ngIf="rate_plan.cancellation !== 'Refundable'; else Refundable"
                                    class="font-inter !leading-4 text-xs font-medium text-palette-gray-700">
                                    {{'COMMON.NON-REFUNDABLE' | translate}}
                                </div>
                                <ng-template #Refundable>
                                    <ng-container *ngIf="rate_plan.refundable">
                                        <!-- Resource id = 0 or Resource id = 2 or Resource id = 3-->
                                        <div
                                            *ngIf="resourceID == 0 || resourceID == 2 || resourceID == 3 || resourceID == 5">
                                            <div class="relative" *ngIf="rate_plan.cancellation_policy_object">
                                                <!-- <i class="sctr-icon-info-circle text-[16px] text-palette-gray-400 cursor-pointer"
                                                    (mouseenter)="rate_plan.showTooltipPolicy = true"
                                                    (mouseleave)="rate_plan.showTooltipPolicy = false"></i> -->
                                                <div
                                                    class="font-inter !leading-4 text-xs font-medium text-palette-gray-700">
                                                    <div
                                                        *ngFor="let room_cancel of rate_plan?.cancellation_policy_object">
                                                        <div *ngIf="room_cancel.to_day === null">
                                                            <div>
                                                                <span
                                                                    *ngIf="room_cancel.to_day == null && room_cancel.from_day == 0; else haveToDay">
                                                                    •
                                                                    {{'ROOM_CANCELLATION_OBJECT.CANCELLATION_MADE_AT_ANYTIME'
                                                                    | translate}}
                                                                </span>
                                                                <ng-template #haveToDay>
                                                                    • {{
                                                                    'ROOM_CANCELLATION_OBJECT.CANCELLATION_MADE_FROM' |
                                                                    translate}}
                                                                    <span *ngIf="room_cancel.from_day !== 0">{{
                                                                        room_cancel.from_day}}</span>
                                                                    {{ room_cancel.from_day == 0 ?
                                                                    ('ROOM_CANCELLATION_OBJECT.DAYS_BEFORE_HOURS' |
                                                                    translate) :
                                                                    ('ROOM_CANCELLATION_OBJECT.DAYS_OR_MORE' |
                                                                    translate) }}
                                                                </ng-template>
                                                                <span *ngIf="room_cancel.charge !== null">
                                                                    <span *ngIf="room_cancel.charge_type === 1">
                                                                        {{'ROOM_CANCELLATION_OBJECT.WILL_BE_CHARGE' |
                                                                        translate}}
                                                                        {{room_cancel.charge | currencyConversion}}.
                                                                    </span>
                                                                    <span *ngIf="room_cancel.charge_type === 2">
                                                                        {{'ROOM_CANCELLATION_OBJECT.WILL_BE_CHARGE' |
                                                                        translate}}
                                                                        {{room_cancel.charge}}%
                                                                        {{'ROOM_CANCELLATION_OBJECT.OF_TOTAL_PRICE' |
                                                                        translate}}.
                                                                    </span>
                                                                    <span *ngIf="room_cancel.charge_type === 3">
                                                                        {{'ROOM_CANCELLATION_OBJECT.WILL_BE_CHARGE_2' |
                                                                        translate}}
                                                                        {{room_cancel.charge}}
                                                                        {{'ROOM_CANCELLATION_OBJECT.FIRST_NIGHT' |
                                                                        translate}}{{currentLanguage === 'en' &&
                                                                        room_cancel.charge
                                                                        > 1 ? 's.' : '.'}}
                                                                    </span>
                                                                </span>
                                                                <span *ngIf="room_cancel.charge === null">
                                                                    {{'ROOM_CANCELLATION_OBJECT.WONT_BE_CHARGE_FEE' |
                                                                    translate}}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div
                                                            *ngIf="room_cancel.to_day >= 0 && room_cancel.to_day !== null">
                                                            <ng-container
                                                                *ngIf="room_cancel.to_day == 0 && room_cancel.from_day == 0; else checkInOtherDay">
                                                                •
                                                                {{'ROOM_CANCELLATION_OBJECT.CANCELLATION_MADE_IN_CHECK_IN_DAY'
                                                                | translate}}
                                                            </ng-container>
                                                            <ng-template #checkInOtherDay>
                                                                •
                                                                {{'ROOM_CANCELLATION_OBJECT.CANCELLATION_MADE_LESS_THAN'
                                                                |
                                                                translate}} {{room_cancel.to_day}}
                                                                {{'ROOM_CANCELLATION_OBJECT.DAYS_BEFORE' | translate}}
                                                            </ng-template>
                                                            <span *ngIf="room_cancel.charge !== null">
                                                                <span *ngIf="room_cancel.charge_type === 1">
                                                                    <!-- {{'ROOM_CANCELLATION_OBJECT.WILL_BE_CHARGE' | translate}} -->
                                                                    {{'ROOM_CANCELLATION_OBJECT.WILL_BE_CHARGE' |
                                                                    translate}}
                                                                    {{room_cancel.charge | currencyConversion}}.
                                                                </span>
                                                                <span *ngIf="room_cancel.charge_type === 2">
                                                                    {{'ROOM_CANCELLATION_OBJECT.WILL_BE_CHARGE' |
                                                                    translate}}
                                                                    {{room_cancel.charge}}%
                                                                    {{'ROOM_CANCELLATION_OBJECT.OF_TOTAL_PRICE' |
                                                                    translate}}.
                                                                </span>
                                                                <span *ngIf="room_cancel.charge_type === 3">
                                                                    {{'ROOM_CANCELLATION_OBJECT.WILL_BE_CHARGE_2' |
                                                                    translate}}
                                                                    {{room_cancel.charge}}
                                                                    {{'ROOM_CANCELLATION_OBJECT.FIRST_NIGHT' |
                                                                    translate}}{{currentLanguage === 'en' &&
                                                                    room_cancel.charge
                                                                    > 1 ? 's.' : '.'}}
                                                                </span>
                                                            </span>
                                                            <span *ngIf="room_cancel.charge === null">
                                                                {{'ROOM_CANCELLATION_OBJECT.WONT_BE_CHARGE_FEE' |
                                                                translate}}
                                                            </span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Resource id = 1 -->
                                        <div *ngIf="resourceID == 1">
                                            <div class="relative" *ngIf="rate_plan.cancellation_policy">
                                                <i class="sctr-icon-info-circle text-[16px] text-palette-gray-400 cursor-pointer"
                                                    (mouseenter)="rate_plan.showTooltipPolicy = true"
                                                    (mouseleave)="rate_plan.showTooltipPolicy = false"></i>
                                                <div *ngIf="rate_plan.showTooltipPolicy"
                                                    class="background-tooltip absolute z-[9999] right-[-80%] top-[110%] font-inter text-xs font-semibold rounded-lg text-center bg-opacity-90 text-palette-base-white p-2 shadow">
                                                    • {{rate_plan.cancellation_policy}}
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <!-- Guest & Tax and fee -->
                    <div *ngIf="checkDisplayTaxesAndCharge(rate_plan) || rate_plan?.max_adult_per_room != null || rate_plan?.rate_comments"
                        class="w-full md:w-[25%] min-h-fit md:min-h-[106px] !px-2 py-1 md:py-0 border-b md:border-b-0  md:border-r border-dashed border-palette-gray-200 h-full flex flex-col !gap-4">
                        <!-- Guest -->
                        <div class="flex flex-col !gap-1" *ngIf="rate_plan?.max_adult_per_room != null">
                            <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.GUEST' |
                                translate}}</p>
                            <div>
                                <p class="text-xs font-medium text-palette-gray-700">
                                    {{rate_plan.max_adult_per_room[0]}} {{'COMMON.ADULT' | translate: {syntax:
                                    rate_plan.max_adult_per_room[0] > 1 ? 's': ''} }}
                                    <span
                                        *ngIf="rate_plan.max_children_per_room[0] !== 0 && rate_plan.max_children_per_room.length !== 0"
                                        class="text-xs font-medium text-palette-gray-700">
                                        , {{rate_plan.max_children_per_room[0]}} {{rate_plan.max_children_per_room[0] >=
                                        2 ?
                                        ("COMMON.CHILDREN" | translate ) : ("COMMON.CHILD" | translate ) }}
                                    </span>
                                </p>
                            </div>
                        </div>
                        <!-- Tax and fee -->
                        <div class="flex flex-col !gap-1" *ngIf="checkDisplayTaxesAndCharge(rate_plan)">
                            <p *ngIf="resourceID !== 3" class="text-xs font-normal uppercase text-palette-gray-500">
                                {{'DETAIL.TAXES_AND_CHARGES' | translate}}</p>
                            <p *ngIf="resourceID === 3" class="text-xs font-normal uppercase text-palette-gray-500">
                                {{'DETAIL.ADDITIONAL_SERVICES' | translate}}</p>
                            <div class="flex items-center !gap-2">
                                <p *ngIf="rate_plan?.tax_data?.taxes !== null && rate_plan?.tax_data?.taxes"
                                    class="text-sm font-medium text-palette-gray-700">
                                    {{getTotalTaxeCharge(rate_plan?.tax_data?.taxes) | currencyConversion}}
                                </p>
                                <div class="relative" *ngIf="getTotalTaxeCharge(rate_plan?.tax_data?.taxes) != 0">
                                    <i class="sctr-icon-info-circle text-[16px] text-palette-gray-400 cursor-pointer"
                                        (mouseenter)="rate_plan.showTooltipTaxes = true"
                                        (mouseleave)="rate_plan.showTooltipTaxes = false"></i>
                                    <div *ngIf="rate_plan.showTooltipTaxes"
                                        class="background-tooltip absolute z-[9999] right-[-80%] top-[110%] font-inter text-xs font-semibold rounded-lg text-center bg-opacity-90 !px-4 !py-2 shadow flex flex-col !gap-1">
                                        <div *ngFor="let item of rate_plan?.tax_data.taxes">
                                            <div *ngIf="!item.included_by_supplier"
                                                class="flex items-center font-inter !gap-2 text-xs font-normal text-white">
                                                <p class="text-left flex-1 whitespace-nowrap">
                                                    {{convertToTitleCase(item.name)}}</p>
                                                <p class="w-auto">{{item.amount | currencyConversion}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Note -->
                        <div *ngIf="rate_plan?.rate_comments && rate_plan?.rate_comments.trim() !== ''"
                            class="flex flex-col !gap-1">
                            <p class="text-xs font-normal uppercase text-palette-gray-500">{{'DETAIL.NOTE' | translate}}
                            </p>
                            <p class="text-xs font-medium text-palette-gray-700 line-clamp-2 cursor-pointer note-text whitespace-pre-line leading-none"
                                pTooltip="{{ rate_plan.rate_comments }}" tooltipPosition="top"
                                tooltipStyleClass="custom-tooltip">
                                {{ rate_plan.rate_comments }}
                            </p>
                        </div>
                    </div>
                    <div [ngClass]="(checkDisplayTaxesAndCharge(rate_plan) || rate_plan?.max_adult_per_room != null) ? 'w-full md:w-1/4':'w-full md:w-1/3'"
                        class="min-h-fit md:min-h-[106px] !pl-2 h-full py-1 md:py-0">
                        <div class="flex flex-col justify-end w-full">
                            <!-- origin price -->
                            <div *ngIf="rate_plan?.origin_price && rate_plan?.origin_price > rate_plan?.net_price"
                                class="text-sm font-normal text-palette-gray-400 line-through ml-auto">
                                <app-price-format [price]="rate_plan.origin_price"></app-price-format>
                            </div>
                            <!-- net price -->
                            <div class="flex items-center mt-[2px] ml-auto w-full">
                                <div class="flex flex-col !gap-1 justify-end text-end w-full">
                                    <div class="flex justify-end md:items-end gap-2 mb-1">
                                        <ng-container *ngIf="rate_plan?.percentage_off > 0;">
                                            <p class=" bg-orange-100 text-amber-600 text-xs font-medium px-2 py-1 rounded self-start md:self-end whitespace-nowrap">
                                                {{ 'DETAIL.OFF' | translate: { syntax: rate_plan.percentage_off } }}
                                            </p>
                                        </ng-container>
                                        <div class="text-palette-blue-600 text-xl max-[1600px]:!text-lg max-[1400px]:!text-base !text-[22px] leading-[30px] font-semibold flex justify-end text-right">
                                            <app-price-format [price]="rate_plan.net_price"></app-price-format>
                                        </div>
                                    </div>
                                    <div class="relative w-full">
                                        <div [tooltip]="'PAYMENT.TOTAL_NIGHT_BEFORE_TAXE_FEE' | translate: { syntax: calculateDateDifference(check_in, check_out) > 1 ?
                                        'nights': 'night', value: calculateDateDifference(check_in, check_out) > 1 ? calculateDateDifference(check_in, check_out) : 1}"
                                            [isTruncated]="true"
                                            class="font-inter text-xs font-normal text-palette-gray-400 whitespace-nowrap text-right !mb-1 !-mt-1 w-full overflow-hidden text-ellipsis">
                                            {{'PAYMENT.TOTAL_NIGHT_BEFORE_TAXE_FEE' | translate: { syntax:
                                            calculateDateDifference(check_in, check_out) > 1 ?
                                            'nights': 'night', value: calculateDateDifference(check_in, check_out) > 1 ?
                                            calculateDateDifference(check_in, check_out) : 1} }}
                                        </div>
                                    </div>
                                </div>
                                <!-- <span *ngIf="resourceID === 0"
                                    class="text-palette-gray-500 text-sm font-normal">/{{'COMMON.NIGHT' | translate: {
                                    syntax: '' } }}</span> -->
                            </div>
                            <div class="ml-auto {{rate_plan?.promotionPercentage !== 0 ? '!mt-2' : '!mt-8'}}">
                                <div *ngIf="is_support_multi_room"
                                    class="border border-palette-gray-300 bg-white w-[119px] h-[44px] rounded-md flex items-center justify-center relative">
                                    <div class="cursor-pointer absolute top-[50%] left-0 translate-y-[-45%] pl-[10px]">
                                        <i class="sctr-icon-minus text-[16px] text-palette-gray-600"
                                            (click)="subtractNumberOfRoom(rate_plan)"></i>
                                    </div>
                                    <span
                                        class="text-sm font-semibold text-palette-gray-600">{{rate_plan?.numberRoomBuy}}</span>
                                    <div class="absolute right-0 top-[50%] cursor-pointer translate-y-[-45%]">
                                        <i class="sctr-icon-plus text-[16px] text-branding-primary-700 pr-[10px]"
                                            (click)="plusNumberOfRoom(rate_plan, item)"></i>
                                    </div>
                                </div>

                                <div *ngIf="!is_support_multi_room" class="!mt-1">
                                    <div pButton (click)="handleClickBookRoom(rate_plan, item)"
                                        class="btn-book-now font-inter text-sm font-semibold flex justify-center items-center whitespace-nowrap cursor-pointer transition-all">
                                        {{'DETAIL.BOOK_ROOM' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-toast></app-toast>