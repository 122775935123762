import { Injectable } from '@angular/core';
import { UserService } from 'src/app/core/services/user/user.service';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountryCodeService {
  listCountryCode: string[] = ['VN', 'US'];
  signal = new BehaviorSubject<string | null>(null);
  currentLocation = new BehaviorSubject<any>(null);
  locationDefault = { //default location for config section Landing page; Epic: SB-8184
    country_code: "VN",  
  }

  constructor(private http: HttpClient) {
    this.getRegional().subscribe({
      next: (res: any) => {
        if(res.success) {
          this.setCurrentLocation(res.data?.[0]);
          if(this.listCountryCode.includes(res.data[0]?.country_code?.toUpperCase())) {
            this.setCountryCode(res.data[0]?.country_code);
          }else{
            this.setCountryCode(this.listCountryCode[0]);
          }
        } else {
          this.setCurrentLocation(this.locationDefault);
          this.setCountryCode(this.listCountryCode[0]);
        }
      },
      error: () => {
        this.setCurrentLocation(this.locationDefault);
        this.setCountryCode(this.listCountryCode[0]);
      }
    });
  }

  
  setCountryCode(countryCode: string | null) {
    this.signal.next(countryCode);
  }

  getCountryCode() {
    return this.signal.asObservable();
  }

  getRegional() {
    return this.http.get<any>(`${environment.baseURL}/regional/me`);
  }

  getCurrentLocation() {
    return this.currentLocation.asObservable();
  }

  setCurrentLocation(currentLocation: any) {
    this.currentLocation.next(currentLocation);
  }

}