import { NgModule } from '@angular/core';
import { CountdownFormatPipe } from './pipes/coundown-format.pipe';
import { TruncateStringPipe } from './pipes/truncate-string.pipe';
import { RelativeTimePipe } from './pipes/relative-time.pipe';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { NumberFormatPipe } from './pipes/number-format.pipe';
import { SortByInteractionsPipe } from './pipes/sort-by-interactions.pipe';
import { SortPipe } from './pipes/sort.pipe';
import { SlugifyPipe } from './pipes/slugify.pipe';
import { BytesConversionPipe } from './pipes/bytes-conversion.pipe';
import { FilterPipe } from './pipes/filter.pipe';
import { DatetimeFormatterModule, DateFormatPipe } from '@soctrip-common/datetime-formatter'
import { DatePipe } from '@angular/common';

import { CommonModule, TitleCasePipe } from '@angular/common';
import { TagModule } from 'primeng/tag';
import { TooltipModule } from 'primeng/tooltip';
import { DialogModule } from 'primeng/dialog';
import { ModalComponent } from './components/modal/modal.component';
import { FeaturedHotelCardComponent } from './components/featured-hotel-card/featured-hotel-card.component';
import { HotDealHotelCardComponent } from './components/hot-deal-hotel-card/hot-deal-hotel-card.component';
import { ToastComponent } from './components/toast/toast.component';
import { Toast, ToastModule } from 'primeng/toast';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DropdownModule } from 'primeng/dropdown';
import { GalleriaModule } from 'primeng/galleria';
import { OverlayModule } from 'primeng/overlay';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { InputNumberModule } from 'primeng/inputnumber';
import { SkeletonModule } from 'primeng/skeleton';
import { TranslateModule } from '@ngx-translate/core';
import { ChartModule } from 'primeng/chart';

import { HotelResultCardComponent } from './components/hotel-result-card/hotel-result-card.component';
import { DoughnutChartComponent } from './components/doughnut-chart/doughnut-chart.component';
import { BookingMenuComponent } from './components/booking-menu/booking-menu.component';
import { HotelPageRoomTypeComponent } from './components/hotel-page-room-type/hotel-page-room-type.component';
import { CustomerReviewCardComponent } from './components/customer-review-card/customer-review-card.component';
import { PaginationCustomComponent } from './components/pagination-custom/pagination-custom.component';
import { TagComponent } from './components/tag/tag.component';
import { ShowImageModalComponent } from './components/show-image-modal/show-image-modal.component';
import { HotelResultCardOnMap } from './components/hotel-result-card-on-map/hotel-result-card-on-map.component';
import { TabMenuComponent } from './components/tab-menu/tab-menu.component';
import { PriceDetailsCheckoutComponent } from './components/price-details-checkout/price-details-checkout.component';
import { CustomizePaginationComponent } from './components/customize-pagination/customize-pagination.component';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { PopupRecommendSignIn } from './components/popup-recommend-sign-in/popup-recommend-sign-in.component';
import { PostDialogComponent } from './components/share-hotel/post-dialog/post-dialog.component';
import { ShareHotelComponent } from './components/share-hotel/share-hotel.component';
import { SignInRequestDialogComponent } from './components/sign-in-request-dialog/sign-in-request-dialog.component';
import { PopupCtaComponent } from './components/popup-cta/popup-cta.component';
import { PriceFormatComponent } from './components/price-format/price-format.component';
import { RatingScoreComponent } from './components/rating-score/rating-score.component';
import { CommonIconText } from './components/common-icon-text/common-icon-text.component';
import { TooltipModuleBooking } from './components/tooltip/tooltip.module';
import { StarComponent } from '@shared/components/star/star.component';
import { MapMarkerPopupComponent } from '@modules/hotel-booking/components/search/map-marker-popup/map-marker-popup.component';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PropertyAmenitiesService } from '@modules/hotel-booking/services/ota/property-amenities.service';
import { PopupQrCodeComponent } from './components/popup-qr-code/popup-qr-code.component';
import { LoadingComponent } from './components/loading/loading.component';
import { InputPhoneNumberComponent } from './components/input-phone-number/input-phone-number.component';
import { PropertyAmenitiesSectionComponent } from './components/OTA/property-amenities-section/property-amenities-section.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService } from 'primeng/api';
import { MeesageErrorFormModule } from './components/meesage-error-form/meesage-error-form.module';
import { DividerModule } from 'primeng/divider';
import { SliderModule } from 'primeng/slider';
import { ButtonComponent } from './components/button/button.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { TableEmptyComponent } from './components/table-empty/table-empty.component';
import { SentenceCasePipe } from '../core/pipes/sentence-case.pipe';
import { CalendarModule } from 'primeng/calendar';
import { PaginatorModule } from 'primeng/paginator';
import { EditorModule } from 'primeng/editor';
import { CalendarDashboardComponent } from './components/calendar-dashboard/calendar-dashboard.component';
import { BarChartComponent } from './components/bar-chart/bar-chart.component';
import { LineChartComponent } from './components/line-chart/line-chart.component';
import { SettingsModule } from '@modules/ota/hotel-ota/components/hotel-management/settings/settings.module';
import { RouterModule } from '@angular/router';
import { OtaInReviewAlertComponent } from './components/ota-in-review-alert/ota-in-review-alert.component';
import { UnderTestComponent } from './components/under-test/under-test.component';
import { CurrencyConversionPipe } from '@soctrip-common/app-security';
import { TermsAndConditionsComponent } from './components/terms-and-conditions/terms-and-conditions.component';
import { PopupBecomeAgencyComponent } from './components/popup-become-agency/popup-become-agency.component';
import { ForbiddenComponent } from './components/forbidden/forbidden.component';
import { ContractInfoComponent } from '@modules/ota/hotel-ota/components/hotel-approval-review/contract-info/contract-info.component';
import { UploadComponent } from '@modules/ota/become-agency/components/request-hotel-agency/property-agency-information/contract-agreement/upload/upload.component';
import { PaginationByUrlComponent } from './components/pagination-by-url/pagination-by-url.component';
import { SafeURLPipe } from './pipes/safe-url.pipe';
import { BannerComponent } from './components/banner/banner.component';
import { SlideBannerComponent } from './components/slide-banner/slide-banner.component';
import { CarouselModule } from 'primeng/carousel';
import { CalendarMultiSelectComponent } from './components/calendar-multi-select/calendar-multi-select.component';
import { TimePickerV2Component } from './components/time-picker-v2/time-picker-v2.component';
import { SeoInfoDirective } from '../core/directives/seo-info.directive';
import { ContractInfoLegalityReviewComponent } from '@modules/ota/hotel-ota/components/hotel-legality-review/contract-info-legality-review/contract-info-legality-review.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { TabComponent } from './components/tab/tab.component';


const sharedPipes = [
  CountdownFormatPipe,
  TruncateStringPipe,
  RelativeTimePipe,
  CustomDatePipe,
  RelativeTimePipe,
  NumberFormatPipe,
  SortByInteractionsPipe,
  SortPipe,
  SlugifyPipe,
  BytesConversionPipe,
  FilterPipe,
  SentenceCasePipe,
  SafeURLPipe
];
const shareProviders = [
  Toast,
  DialogService,
  DynamicDialogRef,
  PropertyAmenitiesService,
  ConfirmationService,
  DatePipe,
  DateFormatPipe,
];
const shareDirective = [
  SeoInfoDirective
];
const shareModules = [
  DialogModule,
  ButtonModule,
  InputTextModule,
  FormsModule,
  ReactiveFormsModule,
  OverlayPanelModule,
  BsDatepickerModule,
  ModalModule,
  DropdownModule,
  ToastModule,
  TagModule,
  TooltipModule,
  GalleriaModule,
  OverlayModule,
  TabViewModule,
  TabMenuModule,
  TranslateModule,
  ProgressSpinnerModule,
  TooltipModuleBooking,
  InputNumberModule,
  CheckboxModule,
  RadioButtonModule,
  SkeletonModule,
  ConfirmDialogModule,
  MeesageErrorFormModule,
  SliderModule,
  DividerModule,
  CalendarModule,
  PaginatorModule,
  EditorModule,
  ChartModule,
  RouterModule,
  CurrencyConversionPipe,
  DatetimeFormatterModule,
  CarouselModule
];
const shareComponents = [
  ModalComponent,
  FeaturedHotelCardComponent,
  HotDealHotelCardComponent,
  ToastComponent,
  SearchBarComponent,
  HotelResultCardComponent,
  BookingMenuComponent,
  HotelPageRoomTypeComponent,
  CustomerReviewCardComponent,
  PaginationCustomComponent,
  TagComponent,
  ShowImageModalComponent,
  HotelResultCardOnMap,
  TabMenuComponent,
  PriceDetailsCheckoutComponent,
  CustomizePaginationComponent,
  TimePickerComponent,
  PopupRecommendSignIn,
  PostDialogComponent,
  ShareHotelComponent,
  SignInRequestDialogComponent,
  PopupCtaComponent,
  PriceFormatComponent,
  RatingScoreComponent,
  CommonIconText,
  StarComponent,
  PopupQrCodeComponent,
  MapMarkerPopupComponent,
  MapMarkerPopupComponent,
  LoadingComponent,
  InputPhoneNumberComponent,
  PropertyAmenitiesSectionComponent,
  ButtonComponent,
  TableEmptyComponent,
  CalendarComponent,
  CalendarDashboardComponent,
  BarChartComponent,
  LineChartComponent,
  DoughnutChartComponent,
  OtaInReviewAlertComponent,
  UnderTestComponent,
  TermsAndConditionsComponent,
  PopupBecomeAgencyComponent,
  ForbiddenComponent,
  ContractInfoComponent,
  UploadComponent,
  PaginationByUrlComponent,
  BannerComponent,
  SlideBannerComponent,
  CalendarMultiSelectComponent,
  TimePickerV2Component,
  ContractInfoLegalityReviewComponent,
  CarouselComponent,
  TabComponent,
];
@NgModule({
  declarations: [sharedPipes, shareComponents, shareDirective],
  imports: [CommonModule, shareModules],
  exports: [CommonModule, sharedPipes, shareComponents, shareModules, shareDirective ],
  providers: [shareProviders, PropertyAmenitiesService, TitleCasePipe],
})
export class SharedModule {}
