import {
  AfterViewInit,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  FormBuilder,
  FormGroup,
  FormGroupDirective,
  Validators,
} from '@angular/forms';
import * as L from 'leaflet';
import { OverlayPanel } from 'primeng/overlaypanel';
import {
  EMPTY,
  Subject,
  catchError,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  finalize,
  takeUntil,
  timeout,
} from 'rxjs';
import { SearchingPlaceControllerService } from '@assistant/angular-map-location-service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TIME_OUT_REQUEST } from '@shared/constant';
import {
  ICity,
  ICountry,
  IDistrict,
} from '@modules/hotel-booking/models/ota-location.model';
import { SoctripMapService } from '@soctrip-common/map';
import {
  CountriesDTO,
  GeneralInfoControllerService,
} from '@booking/angular-booking-generic-service';
import { GeneralInfoDTO } from '@booking/angular-booking-user-data-service';

@Component({
  selector: 'app-location-legality-review',
  templateUrl: './location-legality-review.component.html',
  styleUrls: ['./location-legality-review.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class LocationLegalityReviewComponent
  implements OnInit, AfterViewInit, OnDestroy, OnChanges
{
  @ViewChild('searchLocationOverlay') searchLocationOverlay: OverlayPanel;
  @Input('generalInfo') generalInfo: GeneralInfoDTO;

  cities: ICity[] = [];
  countries: CountriesDTO[] = [];
  districts: IDistrict[] = [];
  locations: any = [];
  otaLocationForm: FormGroup;
  map: L.Map;
  mapOptions: L.MapOptions;
  searchString: string = '';
  marker: L.Marker;
  searchModelChanged: Subject<string> = new Subject<string>();
  isLoading = false;
  onDestroy$: Subject<void> = new Subject();
  isToggleSearch: boolean = false;
  currentLatLng: L.LatLng;
  customIcon = L.divIcon({
    html: '<i class="sctr-icon-solid-marker-pin-03 text-palette-blue-600 text-[40px]"></i>',
    iconSize: [40, 40],
    iconAnchor: [20, 40],
    className: 'marker',
  });

  distanceUnitList = [{ name: 'km' }, { name: 'miles' }];

  constructor(
    private sanitizer: DomSanitizer,
    private soctripMapService: SoctripMapService,
    private searchingPlaceControllerService: SearchingPlaceControllerService,
    private generalInfoControllerService: GeneralInfoControllerService,
    private fb: FormBuilder,
    private readonly formGroupDirective: FormGroupDirective
  ) {}
  ngOnChanges(changes: SimpleChanges): void {
    this.map = this.soctripMapService.getMap() as L.Map;
    this.getDropdownData();
    if (
      changes['isDiscardChange'] ||
      (changes['generalInfo'] && this.generalInfo)
    ) {
      this.otaLocationForm?.patchValue({
        zipCode: this.generalInfo?.zipcode,
        propertyAddress: this.generalInfo?.address,
        distance: this.generalInfo?.distance_from_center,
        coordinates: this.generalInfo?.coordinates,
      });
      this.createMarker(
        this.generalInfo?.coordinates.latitude,
        this.generalInfo?.coordinates.longitude
      );
      this.map?.setView(this.currentLatLng);
    }
  }

  ngOnInit() {
    this.handleSideOverlay();
    this.handleSearchPlaces();
    this.otaLocationForm = this.fb.group({
      country: ['', Validators.required],
      province: ['', Validators.required],
      city: ['', Validators.required],
      zipCode: [, Validators.required],
      propertyAddress: ['', Validators.required],
      distance: [, Validators.required],
      distanceUnit: [this.distanceUnitList[0]],
      coordinates: [],
    });
    this.formGroupDirective?.form?.addControl('location', this.otaLocationForm);
  }

  ngAfterViewInit(): void {
    this.map = this.soctripMapService.getMap() as L.Map;
  }

  ngOnDestroy(): void {
    this.map.off();
    this.map.remove();
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  createMarker(latitude: number, longitude: number) {
    this.map = this.soctripMapService.getMap() as L.Map;
    this.marker?.remove();
    const latLng = L.latLng(latitude, longitude);
    if (latLng?.lat && latLng?.lng) {
      this.marker = L.marker(latLng, { icon: this.customIcon })?.addTo(
        this.map
      );
      this.currentLatLng = latLng;
    }
    this.otaLocationForm?.get('coordinates')?.setValue(this.currentLatLng);
  }

  get f(): { [key: string]: AbstractControl } {
    return this.otaLocationForm.controls;
  }

  handleSearchPlaces() {
    this.searchModelChanged
      .pipe(debounceTime(500), distinctUntilChanged())
      .subscribe((searchText: string) => {
        this.getPlaces(searchText);
      });
  }

  getDropdownData(data: GeneralInfoDTO = this.generalInfo) {
    if (data?.country_code && !this.countries.length) {
      combineLatest([
        this.generalInfoControllerService.getAllCountriesFromShipmentService(),
        this.generalInfoControllerService.getAllCitiesFromShipmentService(
          data.country_code
        ),
        this.generalInfoControllerService.getAllDistrictsFromShipmentService(
          data.city_id
        ),
      ])
        .pipe(
          timeout(TIME_OUT_REQUEST),
          takeUntil(this.onDestroy$),
          catchError((error) => {
            return EMPTY;
          })
        )
        .subscribe(([contriesRes, citiesRes, districtsRes]) => {
          if (contriesRes.data?.length && contriesRes.success) {
            const tmpCountries: CountriesDTO = contriesRes.data.find(
              (country) => country.country_code === data.country_code
            )!;
            this.otaLocationForm.patchValue({
              country: tmpCountries,
            });
            this.countries = contriesRes.data;
          }
          if (citiesRes.success) {
            const tmpCities = citiesRes.data.find(
              (city: ICity) => city.city_id === data.city_id
            );
            this.otaLocationForm.patchValue({
              province: tmpCities,
            });
            this.cities = citiesRes.data;
          }
          if (districtsRes.success) {
            const tmpdistricts = districtsRes.data.find(
              (district: IDistrict) => district.district_id === data.district_id
            );
            this.otaLocationForm.patchValue({
              city: tmpdistricts,
            });
            this.districts = districtsRes.data;
          }
        });
    }
  }

  getPlaces(searchText?: string) {
    this.isLoading = true;
    this.searchingPlaceControllerService
      .searchLocation(searchText, 0, 5)
      .pipe(
        timeout(TIME_OUT_REQUEST),
        takeUntil(this.onDestroy$),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe((res) => {
        if (res.success && res.data) {
          this.locations = res.data?.places?.data;
        } else if (!res.success) {
          this.locations = [];
        }
      });
  }

  // Make HTML string safe for innerHTML method
  getSafeHtml(html: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(
      this.boldMatchCharacters(html, this.searchString)
    );
  }

  boldMatchCharacters(sentence = '', characters = '') {
    const regEx = new RegExp(characters.toLowerCase(), 'gi');
    return sentence?.replace(regEx, '<strong>$&</strong>');
  }

  handleClickInput(e: Event) {
    this.getPlaces(this.searchString);
    this.isToggleSearch = true;
    this.searchLocationOverlay?.show(e);
  }

  onBlurInput(e: Event) {
    this.isToggleSearch = false;
    this.searchLocationOverlay?.hide();
  }

  handleClickLocation(location: any) {
    this.searchLocationOverlay.hide();
    this.searchString = location.main_text;
    this.createMarker(location.lat, location.lon);
    this.setLocationInMap(location.lat, location.lon, 12);
  }

  setLocationInMap(latitude: number, longitude: number, zoom: number = 6) {
    const latlng = L.latLng(latitude, longitude);
    this.map.setView(latlng, zoom);
  }

  getCountries() {
    !this.countries.length &&
      this.generalInfoControllerService
        .getAllCountriesFromShipmentService()
        .pipe(timeout(TIME_OUT_REQUEST), takeUntil(this.onDestroy$))
        .subscribe((res: any) => {
          if (res.success && res.data) {
            this.countries = res.data;
          }
        });
  }

  handleCountryDropdown(data: any) {
    this.districts = [];
    const country: ICountry = data.value;
    this.generalInfoControllerService
      .getAllCitiesFromShipmentService(country.country_code)
      .pipe(timeout(TIME_OUT_REQUEST), takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.success && res.data) {
          this.cities = res.data;
          this.createMarker(country.latitude, country.longitude);
          this.setLocationInMap(country.latitude, country.longitude);
        }
      });
  }

  validFields() {
    this.otaLocationForm.controls['country'].markAllAsTouched();
    this.otaLocationForm.controls['province'].markAllAsTouched();
  }

  handleCityDropdown(data: any) {
    const city: ICity = data.value;
    this.generalInfoControllerService
      .getAllDistrictsFromShipmentService(city.city_id)
      .pipe(timeout(TIME_OUT_REQUEST), takeUntil(this.onDestroy$))
      .subscribe((res) => {
        if (res.success && res.data) {
          this.districts = res.data;
          this.createMarker(city.latitude, city.longitude);
          this.setLocationInMap(city.latitude, city.longitude);
        }
      });
  }

  handleDistrictDropdown(data: any) {
    const district: IDistrict = data.value;
    this.createMarker(district.latitude, district.longitude);
    this.setLocationInMap(district.latitude, district.longitude, 10);
  }

  handleSideOverlay() {
    const sidebarOverlay = document.querySelector('.ota-location-side-bar');
    sidebarOverlay?.addEventListener('mouseover', () => {
      this.map.doubleClickZoom.disable();
      this.map.dragging.disable();
      this.map.removeEventListener('click');
    });

    sidebarOverlay?.addEventListener('mouseleave', () => {
      this.map.scrollWheelZoom.enable();
      this.map.doubleClickZoom.enable();
      this.map.dragging.enable();
      this.map.addEventListener('click', (e) => {
        this.createMarker(e?.latlng?.lat, e?.latlng?.lng);
      });
    });
  }
}
