<div id="container-search"
  class="{{receivedData.containerClass}} filter-nav search-box mt-2 pt-2 pb-4 h-full md:!h-[60px] bottom-52 w-full items-center bg-gray-200 rounded-xl px-1 lg:flex lg:!h-[60px] lg:justify-between lg:bg-white xl:!p-[6px] xl:pr-[2px]"
  [ngClass]="{'iscontainer-search': receivedData.isShowPlace}">
  <div class="container-place relative flex h-full w-full items-center pb-2 !pt-2 lg:!w-[28%]"
    [ngClass]="{'isDisabled': receivedData.isShowPlace}">
    <span id="inputPlace" class="p-input-icon-left gray-" #destination>
      <i class="box-icon-service px-2">
        <i class="sctr-icon-marker-pin-02 text-xl leading-5 text-palette-blue-600"></i>
      </i>
      <input #inputField #inputRef autocomplete="off" id="myInput" type="text"
        class="h-[50px] w-full truncate text-base font-medium not-italic" pInputText
        [(ngModel)]="receivedData.destination_name"
        (click)="onClickInputPlace($event)" (focus)="onInputPlaceFocus()" (blur)="onInputPlaceBlur()"
        (input)="onInputChange($event)" [placeholder]="placeholderText | translate"
        [ngClass]="{'isfocus truncate': isFocused, 'isblur truncate': !isFocused}">
      <i *ngIf="isButtonClearIcon" (click)="onClearTextSearch()" class="sctr-icon-x-close absolute text-palette-gray-300 right-2 hover:text-palette-gray-800 hover:cursor-pointer"></i>
    </span>
  </div>
  <div class="xl:!w-[18px] xl:p-2" [ngClass]="{'isDisabled':receivedData.isShowPlace}">
    <div class="xl:relative xl:z-10 xl:h-[28px] xl:w-full xl:border xl:bg-gray-300 xl:py-[12px]"></div>
  </div>
  <div class="container-select-date relative date-range-picker lg:!w-[38%] flex h-full w-full pb-2 xl:!pt-[9px] ">
    <div class="relative flex h-full w-full items-center">
      <span class="p-input-icon-left flex items-center" #rangeCalendarCheckIn>
        <i class="box-icon-service px-2">
          <i class="sctr-icon-calendar text-xl leading-5 text-palette-blue-600"></i>
        </i>
        <input id="check-in-date" #selectDateCheckIn type="text"
          class="w-full truncate font-inter text-base font-medium not-italic text-black xl:h-[51px]" pInputText
          [(ngModel)]="valueCheckIn" (click)="showCalendarDayCheckIn($event)" (dblclick)="onDatepickerHide()"
          placeholder="{{'SEARCH.PLACEHOLDER.CHECK_IN_DATE' | translate}}" readonly />
      </span>
    </div>
    <div class="!w-[18px] p-2">
      <div class="relative xl:bottom-2 xl:z-0 xl:h-[28px] xl:w-full xl:border xl:bg-gray-300 xl:py-[12px]"></div>
    </div>
    <div class="relative flex h-full w-full items-center">
      <span class="p-input-icon-left w-full" #rangeCalendarCheckOut>
        <i class="box-icon-service">
          <i class="sctr-icon-calendar text-xl leading-5 text-palette-blue-600"></i>
        </i>
        <input #selectDateCheckout type="text" id="check-out-date"
          class="xl:input-2 w-full truncate font-inter text-base font-medium not-italic text-black placeholder:font-inter placeholder:text-base placeholder:font-medium xl:h-[51px]"
          pInputText [(ngModel)]="valueCheckOut" (click)="showCalendarDayCheckOut($event)"
          (dblclick)="onDatepickerHide()" placeholder="{{'SEARCH.PLACEHOLDER.CHECK_OUT_DATE' | translate}}" readonly />
      </span>

      <div #rangeCalendarPanel class=" custom-overlaypanel absolute top-[58px] bg-slate-500">
        <div class="custom-root-element " bsDaterangepicker [ngClass]="" #daterangepicker="bsDaterangepicker"
          [minDate]="currentDate!" [maxDate]="maxDate!" [bsConfig]="{
                  showWeekNumbers: false,
                  customTodayClass: 'customTodayClass',
                  containerClass:'containerClassCalendar'
                  }" [outsideClick]="false" (onHidden)="onDatepickerHide()" (onShown)="onDatepickerShown()"
                  [isOpen]="isShowCalendar"
          [(bsValue)]="selectedDates" (bsValueChange)="onDateRangeChange($event)" [style]="{zIndex: '10000'}"></div>
      </div>
    </div>
  </div>
  <div class="xl:!w-[18px] xl:p-2">
    <div class="xl:z-10 xl:h-[28px] xl:w-full xl:border xl:bg-gray-300 xl:py-[12px]"></div>
  </div>
  <span id="selectPeople" class="container-select-people relative mr-2 top-[6px] flex !h-[51px] lg:h-full w-full cursor-pointer
     items-center justify-around space-x-2 rounded-lg bg-white p-3 text-left xl:!w-[35%] lg:rounded-xl xl:top-0"
    (click)="handleSelectPeople($event)" (blur)="hideOverlaySelectPeople()" tabindex="0" #selectOptionRef>
    <div class="box-icon-service" #amountPeople>
      <i class="sctr-icon-users-02 text-xl leading-5 text-palette-blue-600"></i>
    </div>
    <div id="text-amount-people"
      class=" w-10/12 text-ellipsis overflow-hidden whitespace-nowrap text-base font-medium text-black flex">
      {{ receivedData.numberOfRooms }} &nbsp; <p class="first-letter:capitalize">{{'COMMON.ROOM' | translate: { syntax:
        receivedData.numberOfRooms != 1 ?
        's': '' } }} </p>
      &nbsp; - &nbsp;{{ receivedData.numberOfAdults }} &nbsp;<p class="first-letter:capitalize">{{'COMMON.ADULT' |
        translate: { syntax: receivedData.numberOfAdults !=
        1 ? 's': '' } }} </p>
      &nbsp; - &nbsp; {{ receivedData.numberOfChildren}} &nbsp; <p class="first-letter:capitalize">{{
        receivedData.numberOfChildren != 1 ? ("COMMON.CHILDREN" | translate
        ) : ("COMMON.CHILD" | translate ) }}
      </p>
    </div>
    <div class="box-icon-service">
      <i class="sctr-icon-chevron-down text-xl leading-5 text-palette-gray-500"></i>
    </div>
  </span>
  <div
    class="container-button relative  !h-full xl:!h-[52px] rounded-xl xl:!top-[1px] left-[2px] xl:flex xl:cursor-pointer xl:items-center xl:space-x-2 xl:bg-white">
    <p-button [label]="receivedData.titleSearchButton | translate" [disabled]="receivedData.isDisableSearchBtn" 
    [icon]="receivedData.titleSearchButton == 'DETAIL.APPLY_SEARCH'? 'sctr-icon-refresh-ccw-02 text-white text-xl' : ''"
      class="search-hotel relative h-full w-fit whitespace-nowrap font-normal"

      [ngClass]="{'buttonText':receivedData.isShowPlace}" (onClick)="onClickSearchHotel()"></p-button>
  </div>
</div>
<app-toast></app-toast>
<p-overlayPanel #addressPanel [appendTo]="destination" [dismissable]="true" (onHide)="onHideAddressOverlay()"
  (onShow)="onShowAddressOverlay()">
  <ng-template pTemplate="content" style="position: relative; top: 20px">
    <div id="addressPanel" class=" w-full md:!w-full lg:!w-[652px] xl:h-auto">
      <div class="border-b">
        <p class="!px-4 !py-3 text-base font-semibold xl:!text-lg">
          {{'SEARCH.SOME_FAMOUS_PLACES' | translate}}
        </p>
      </div>
      <div class="flex items-center gap-3 !pl-4 cursor-pointer hover:opacity-70" (click)="handleHotelNearU()">
        <img class="m-[10px]" style="height: 20px" src="assets/images/home/icon/mark.svg" alt="" />
        <p class="font-inter text-sm font-medium leading-5">
          {{'SEARCH.HOTEL_NEAR_YOU' | translate}}
        </p>
      </div>
      <div class="search-address-list max-h-[212px] space-y-3 overflow-y-auto xl:max-h-[450px] xl:w-auto">
        <span *ngIf="isLoading; then loading; else loaded"></span>
        <ng-template #loading>
          <ng-container *ngFor="let i of renderArray(4)">
            <div class="flex m-2">
              <div class="mr-2">
                <p-skeleton size="4rem">
                </p-skeleton>
              </div>
              <div class="flex items-center flex-1">
                <div class="flex flex-col gap-2 w-full">
                  <p-skeleton width="50%" height="1rem">
                  </p-skeleton>
                  <p-skeleton width="90%" height="1rem">
                  </p-skeleton>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #loaded>
          <span *ngIf="addressList?.length then hadData; else emptyData"></span>
          <ng-template #emptyData>
            <div class="flex items-center flex-col p-3">
              <i
                class="sctr-icon-search-lg !w-10 !h-10 !leading-10 text-2xl text-center rounded-[50%] bg-palette-blue-100 text-palette-blue-600"></i>
              <div class="mt-2 text-palette-gray-900 text-base font-semibold flex justify-center">
                {{'SEARCH_RESULT.NO_RESULTS_FOUND' | translate}}</div>
            </div>
          </ng-template>
          <ng-template #hadData>
            <div *ngFor="let address of addressList"
              class="address-item flex cursor-pointer items-center justify-between pl-2 hover:bg-gray-100 xl:m-0 xl:h-16"
              (click)="onHotelSelected(address)">
              <div class="flex items-center !py-3 px-2 xl:w-full">
                <div class="{{address.bg_color}} flex h-10 w-10 max-w-[40px] items-center justify-center rounded-full">
                  <div class="!h-5 !w-5 text-center">
                    <i class="{{address.icon_class}}"></i>
                  </div>
                </div>
                <div class="max-w-[90%]">
                  <div class="ml-3 text-sm xl:text-base truncate">{{ address.object_name }}</div>
                  <div class="ml-3 text-sm text-gray-500 line-clamp-1">{{ address.address }}</div>
                </div>
              </div>
              <!-- <div *ngIf="address.object_type !== 'Property' && address.num_of_objects > 0"
                class="h-full w-full !px-4 py-2">
                <div class="flex h-full min-w-full items-center justify-end !text-right">
                  <div class="text-sm xl:text-base">
                    {{ address.num_of_objects }} {{'COMMON.HOTEL' | translate: { syntax: address.num_of_objects != 1
                    ?'s':
                    '' } }}
                  </div>
                </div>
              </div> -->
            </div>
          </ng-template>
        </ng-template>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-overlayPanel class="room-adult-child !z-0" #filterAmountPeoplePanel [appendTo]="amountPeople">
  <ng-template pTemplate="content">
    <div id="container-num-room" class="!w-full md:!w-full  space-y-3 !p-4 lg:!w-[322px]">
      <div class="flex h-10 items-center justify-evenly">
        <div class="number-of-rooms w-3/5 text-left">
          <p class="select-number-title left-5 capitalize">
            {{'COMMON.ROOM' | translate: { syntax: receivedData.numberOfRooms != 1 ? 's': '' } }}
          </p>
        </div>
        <div class="flex h-full w-2/5 items-center rounded-md border">
          <div class="flex w-1/3 justify-center" (click)="subtractNumberOfRooms()">
            <div *ngIf="isSubtractionAllowedRoom(); else isSubtractionNotAllowed ">
              <i class="sctr-icon-minus text-base text-palette-blue-600"></i>
            </div>
            <ng-template #isSubtractionNotAllowed>
              <i class="sctr-icon-minus text-base text-palette-gray-300"></i>
            </ng-template>
          </div>
          <p class="number-select-change flex w-1/3 justify-center">{{ receivedData.numberOfRooms }}</p>
          <div class="flex w-1/3 justify-center" (click)="plusNumberOfRooms()">
            <div *ngIf="isPlusNotAllowedRoom(); else isPlusAllowRoom">
              <i class="sctr-icon-plus text-base text-palette-blue-600"></i>
            </div>
            <ng-template #isPlusAllowRoom>
              <i class="sctr-icon-plus text-base text-palette-gray-300"></i>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="w-3/5 text-left">
          <p class=".select-number-title left-5 first-letter:capitalize">
            {{'COMMON.ADULT' | translate: { syntax: receivedData.numberOfAdults != 1 ? 's': '' } }}
          </p>
          <p class="text-attention">
            {{'SEARCH.AGE' | translate}} > 17
          </p>
        </div>
        <div class="flex h-10 w-2/5 items-center rounded-md border">
          <div class="flex w-1/3 justify-center" (click)="subtractNumberOfAdults()">
            <div *ngIf="isSubtractionAllowedAdult(); else isSubtractionNotAllowed">
              <i class="sctr-icon-minus text-base text-palette-blue-600"></i>
            </div>
            <ng-template #isSubtractionNotAllowed>
              <i class="sctr-icon-minus text-base text-palette-gray-300"></i>
            </ng-template>
          </div>
          <p class="number-select-change flex w-1/3 justify-center">{{ receivedData.numberOfAdults }}</p>
          <div class="flex w-1/3 justify-center" (click)="plusNumberOfAdults()">
            <div *ngIf="isPlusNotAllowedAdult(); else isPlusAllowAdult">
              <i class="sctr-icon-plus text-base text-palette-blue-600"></i>
            </div>
            <ng-template #isPlusAllowAdult>
              <i class="sctr-icon-plus text-base text-palette-gray-300"></i>
            </ng-template>
          </div>
        </div>
      </div>
      <div class="flex items-center justify-between">
        <div class="w-3/5 text-left">
          <p class=".select-number-title left-5 first-letter:capitalize">
            {{ receivedData.numberOfChildren != 1 ? ("COMMON.CHILDREN" | translate ) : ("COMMON.CHILD" | translate ) }}
          </p>
          <p class="text-attention">
            {{'SEARCH.AGE' | translate}} 0 - 17
          </p>
        </div>
        <div class="flex h-10 w-2/5 items-center rounded-md border">
          <div class="flex w-1/3 justify-center" (click)="subtractNumberOfChildren(dropdowns.length - 1)">
            <div *ngIf="isSubtractionAllowedChild(); else isSubtractionNotAllowed">
              <i class="sctr-icon-minus text-base text-palette-blue-600"></i>
            </div>
            <ng-template #isSubtractionNotAllowed>
              <i class="sctr-icon-minus text-base text-palette-gray-300"></i>
            </ng-template>
          </div>
          <p class="number-select-change flex w-1/3 justify-center">{{ receivedData.numberOfChildren }}</p>
          <div class="flex w-1/3 justify-center" (click)="plusNumberOfChildren()">
            <div *ngIf="isPlusNotAllowedChild(); else isPlusAllowChild">
              <i class="sctr-icon-plus text-base text-palette-blue-600"></i>
            </div>
            <ng-template #isPlusAllowChild>
              <i class="sctr-icon-plus text-base text-palette-gray-300"></i>
            </ng-template>
          </div>
        </div>
      </div>
      <form [formGroup]="myFormDropdownAge">
        <div formArrayName="dropdowns" id="dropdown-children-age" class="m-0 grid w-[290px] grid-cols-2 !gap-4">
          <div *ngFor="let dropdown of dropdowns.controls; let i = index">
            <p-dropdown #childElement [options]="ageChildren" optionLabel="age_word" optionValue="age_number"
              [formControl]="dropdown" class="dropdown-age truncate" placeholder="{{'SEARCH.AGE' | translate}}"
              (onFocus)="onFocusDropdownAge()">
            </p-dropdown>
          </div>
        </div>
      </form>
      <div *ngIf="receivedData.numberOfChildren > 0">
        <p class="text-attention">
          {{'SEARCH.AGE_DESCRIPTION' | translate}}
        </p>
      </div>
    </div>
  </ng-template>
</p-overlayPanel>

<p-dialog 
  class="location-popup"
  [breakpoints]="{ '1920': '384px', 'sm': '200px' }" 
  [dismissableMask]="true"
  [modal]="true"
  [(visible)]="visibleAllowLocation">
    <div class="!flex justify-center">
      <img src="assets/icons/location/Locations.svg">
    </div>
    <div class="!px-6 text-center max-sm:max-w-[330px] sm:max-w-[384px]">
      <p class="font-inter text-lg !font-semibold text-palette-gray-900">{{ 'SEARCH.ALLOW_LOCATION' | translate }}</p>
      <p class="!mt-2 font-inter text-sm !font-normal text-palette-gray-600">{{ 'SEARCH.KNOW_YOUR_LOCATION' | translate }}</p>
    </div>
    <div class="!mt-4 !px-6 !pt-2 !pb-6 flex justify-center">
      <button 
        class="!px-5 !py-3 bg-palette-blue-600 rounded-lg text-palette-base-white hover:opacity-80"
        (click)="visibleAllowLocation = false"
      >
        {{ 'SEARCH.OK_GOT_IT' | translate }}
      </button>
    </div>
</p-dialog>