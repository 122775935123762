import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { telephoneCode, CountryCode } from "@modules/users/mock/telephone-code";

@Component({
  selector: 'app-input-phone-number',
  templateUrl: './input-phone-number.component.html',
  styleUrls: ['./input-phone-number.component.scss']
})
export class InputPhoneNumberComponent implements OnInit {
  @Input() id: string;
  @Input() phone?: { phone_number: string; phone_area_code: string };
  @Output() phoneChanged: EventEmitter<void> = new EventEmitter<void>();
  @Output() blurForm: EventEmitter<void> = new EventEmitter<void>();
  public countries: CountryCode[];
  public phoneNumberForm: FormGroup;
  public phoneRegex: RegExp;
  public phoneNumberValue: any;
  defaultCountry = 'VN';
  valueChanges: any;
  isFocused: boolean = false;
  
  constructor(private formBuilder: FormBuilder ) {} 

  ngOnInit(): void {
    this.initRegex();
    this.initFormBuilder();
    this.initUiData();
    this.subscribePhoneChanged()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['phone']) {
      if(changes['phone'].currentValue) {
        this.initRegex();
        this.initFormBuilder();
        this.initUiData();
      }
    }
  }

  onFocus() {
    this.isFocused = true;
  }

  onBlur() {
    this.isFocused = false;
  }

  subscribePhoneChanged(){
    this.phoneNumberForm.valueChanges.subscribe(value => {
      if(this.phoneNumberForm.pristine === false) {
        this.phoneChanged.emit(this.phoneNumberForm.value);
      }
    });
  }

  initUiData() {
    this.countries = telephoneCode;
    if (this.phone) {
      const countryFlag = telephoneCode.find(item => item.code === this.phone?.phone_area_code);
      this.phoneNumberForm.get('phoneNumberCode')?.setValue(countryFlag);
      this.phoneNumberForm.get('phoneNumber')?.setValue(this.phone.phone_number);
    } else {
      const countryFlag = telephoneCode.find(item => item.id === this.defaultCountry);
      this.phoneNumberForm?.get('phoneNumberCode')?.setValue(countryFlag);
    }
  }

  initFormBuilder() {
    this.phoneNumberForm = this.formBuilder.group({
      phoneNumber: ['', [Validators.required, Validators.pattern(this.phoneRegex)]],
      phoneNumberCode: [''],
    });
  }

  onBlurField(fieldName: string, formGroup: FormGroup) {
    const control = formGroup.get(fieldName);
    if (control?.touched && control?.errors?.['required']) {
      this.blurForm.emit({ ...this.phoneNumberForm.value, error: 'required' });
    } else {
      this.blurForm.emit(this.phoneNumberForm.value);
    }
  }

  onKeyPress(e: any) {
    if (e.which < 48 || e.which > 57 || e.target.value.length === 15) {
      e.preventDefault();
    }
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedText = event.clipboardData?.getData('text/plain');
    const maxLength = 15; 
    if (pastedText && typeof pastedText === 'string') {
      if (pastedText.length > maxLength) {
        const truncatedText = pastedText.substring(0, maxLength);
        this.phoneNumberForm.controls['phoneNumber'].setValue(truncatedText);
      } else {
        this.phoneNumberForm.controls['phoneNumber'].setValue(pastedText);
      }
    }
  }

  isNumeric(value: any): boolean {
    const parsedValue = parseFloat(value);
    return !isNaN(parsedValue);
  }

  initRegex() {
    this.phoneRegex = /^\d{5,15}$/;
  }

  get childFormControl() {
    return this.phoneNumberForm;
  }
  
  get f(): { [key: string]: AbstractControl } {
    return this.phoneNumberForm.controls;
  }

  getIcon(country: any): string {
    return country.icon;
  }

}
