import moment from "moment";

export const ROLES = {
  user: 'user',
  manager: 'manager',
  admin: 'admin',
};
export const USER_PERMISSION = [ROLES.user, ROLES.manager, ROLES.admin];
export const MANAGER_PERMISSION = [ROLES.admin, ROLES.manager];
export const ADMIN_PERMISSION = [ROLES.admin];

export const DEFAULT_SEARCH_PARAMS = {
  page_num: 1,
  page_size: 25,
  num_of_adults: 2,
  num_of_children: 0,
  num_of_rooms: 1,
  child_ages_list: [],
  currency: 'USD',
  country_code: 'US',
  checkin: moment().format('yyyy-MM-DD'),
  checkout: moment().add(1, 'day').format('yyyy-MM-DD')
};

export const URL_COUNTRY_CODE = 'https://ipgeolocation.abstractapi.com/v1/?api_key=';
export const APIKEY_COUNTRY_CODE = 'da81649210074b6f8003c7903841046f';
export const HLCM_ID = '164dd7c0-8e67-11ee-9442-cfd2e4df7803'; //Hotellink CM
export const TIME_OUT_REQUEST = 60000;
export const CHARACTER_TRANSLATION_LIMIT = 5000;
export const MAX_TRUNCATED_TEXT_HEIGHT = 120;

export const MAX_QTY_REACTIONS_STORAGE = 1000;
export const PAGE_SIZE_DEFAULT = 10;
export const PAGE_NUM_DEFAULT = 0;
export const MAX_TRUNCATED_TEXT_HEIGHT_COMMENT = 40;

export const MAX_RANGE_DATE_AVAILABILITY = 18 //max month

export const OVERALL_RATING_TEXT = 'OVERALL_RATING_TEXT'
export const OVERALL_RATING_TEXT_CONTENT = 'Overall rating text'
export const MEDIA_FILE_SIZE_LIMITS_BYTES = {
  image: 5242880,
  video: 104857600
};

export const MEDIA_FILE_FORMAT_LIMITS = {
  image: [
    'image/avif',
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/jpg',
    'image/bmp',
    'image/svg+xml',
    'image/webp',
    'image/tiff'
  ],
  video: [
    'video/mp4',
    'video/mpeg',
    'video/ogg',
    'video/webm',
    'video/3gpp',
    'video/x-msvideo',
    'video/x-ms-wmv',
    'video/quicktime'
  ]
}

export const MEDIA_TYPE = {
  image: 'Image',
  video: 'Video',
  file: 'File'
};

export const TOAST_MESSAGE_LIFETIME_DURATIONS = {
  short: 2000,
  medium: 5000,
  long: 7000
};

export const TOAST_MESSAGE_SEVERITY_LEVELS = {
  warn: 'warn',
  info: 'info',
  success: 'success',
  error: 'error'
};

export const FLIGHT = {
  page_size: 9
}

export const currencies = {
  "US": { currency : "USD" },
  "VN": { currency: "VND" },
}

