export const createSlug = (text: string) => {
  const diacriticsMap: { [key: string]: string } = {
    à: 'a',
    á: 'a',
    ả: 'a',
    ã: 'a',
    ạ: 'a',
    ă: 'a',
    ắ: 'a',
    ằ: 'a',
    ẳ: 'a',
    ẵ: 'a',
    ặ: 'a',
    â: 'a',
    ấ: 'a',
    ầ: 'a',
    ẩ: 'a',
    ẫ: 'a',
    ậ: 'a',
    đ: 'd',
    è: 'e',
    é: 'e',
    ẻ: 'e',
    ẽ: 'e',
    ẹ: 'e',
    ê: 'e',
    ế: 'e',
    ề: 'e',
    ể: 'e',
    ễ: 'e',
    ệ: 'e',
    ì: 'i',
    í: 'i',
    ỉ: 'i',
    ĩ: 'i',
    ị: 'i',
    ò: 'o',
    ó: 'o',
    ỏ: 'o',
    õ: 'o',
    ọ: 'o',
    ô: 'o',
    ố: 'o',
    ồ: 'o',
    ổ: 'o',
    ỗ: 'o',
    ộ: 'o',
    ơ: 'o',
    ớ: 'o',
    ờ: 'o',
    ở: 'o',
    ỡ: 'o',
    ợ: 'o',
    ù: 'u',
    ú: 'u',
    ủ: 'u',
    ũ: 'u',
    ụ: 'u',
    ư: 'u',
    ứ: 'u',
    ừ: 'u',
    ử: 'u',
    ữ: 'u',
    ự: 'u',
    ỳ: 'y',
    ý: 'y',
    ỷ: 'y',
    ỹ: 'y',
    ỵ: 'y',
  };
  const noDiacritics = text
    .toLowerCase()
    .replace(/[\u0300-\u036F]/g, '')
    .replace(/[^\u0000-\u007E]/g, (char) => diacriticsMap[char] || char);
  return noDiacritics.replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');
};
export const formatCurrency = (price: number) => {
  return new Intl.NumberFormat('en-US', {
    style: 'decimal',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(price);
};
export const renderStar = (number: number): any => {
  return [...Array(number)];
};

export const scrollToTop = (behavior?: ScrollBehavior) => {
  window.scrollTo({ top: 0, left: 0, behavior: behavior });
};

export function parseJwt(token: string) {
  try {
    const base64Url = token.split('.')[1]; // Lấy phần payload
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/'); // Chuyển đổi sang Base64
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2))
        .join('')
    );
    return JSON.parse(jsonPayload); // Parse JSON từ payload
  } catch (error) {
    console.error('Invalid token:', error);
    return null;
  }
}

export function createJwtPayload(obj: object): string | null {
  try {
    const jsonPayload = JSON.stringify(obj);

    const base64 = btoa(
      encodeURIComponent(jsonPayload).replace(/%([0-9A-F]{2})/g, (_, hex) =>
        String.fromCharCode(parseInt(hex, 16))
      )
    );

    const base64Url = base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');

    return base64Url;
  } catch (error) {
    console.error('Error creating Base64 payload:', error);
    return null;
  }
}

export function createJwtBase64( payload: object): string | null {
  try {
    const header = {
      alg: "HS256",
      typ: "JWT"
    };
    const jsonHeader = JSON.stringify(header);
    const jsonPayload = JSON.stringify(payload);

    const base64Header = btoa(
      encodeURIComponent(jsonHeader).replace(/%([0-9A-F]{2})/g, (_, hex) =>
        String.fromCharCode(parseInt(hex, 16))
      )
    );
    const base64Payload = btoa(
      encodeURIComponent(jsonPayload).replace(/%([0-9A-F]{2})/g, (_, hex) =>
        String.fromCharCode(parseInt(hex, 16))
      )
    );

    const base64UrlHeader = base64Header
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');
    const base64UrlPayload = base64Payload
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=+$/, '');

    return `${base64UrlHeader}.${base64UrlPayload}`;
  } catch (error) {
    console.error('Error creating JWT Base64:', error);
    return null;
  }
}
