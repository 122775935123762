<div class="relative booking-menu w-full flex flex-col min-[480px]:flex-row gap-2">
  <div class="service flex h-10 w-full items-center justify-between gap-[9px] p-1 rounded-lg bg-white lg:w-fit">
    <ng-container *ngFor="let item of bookingTypeList">
      <a [href]="handleClickNav(item)"
        class="h-[32px] !px-3 py-[6px] rounded-md hover:bg-palette-gray-100 transition-all"
        [ngClass]="bookingType === item.short_name ? 'bg-palette-pink-100 px-1 text-palette-pink-600' : '' ">
        <common-icon-text class="flex justify-center items-center h-5" [icon]="item.icon"
          [iconStyle]="item.text_color + ' text-base flex justify-center items-end'" [text]="item.booking_type_name"
          [textStyle]="'ml-1 lg:ml-2 whitespace-nowrap text-[9px] sm:text-sm font-semibold sm:block'"></common-icon-text>
      </a>
    </ng-container>
  </div>
  <button
    class="rounded-lg min-w-[172px] px-4 py-2 items-center justify-center text-xs md:text-sm font-semibold leading-5
   text-palette-blue-600 hover:text-palette-blue-700 bg-white hover:!bg-palette-blue-100 transition-colors hidden sm:flex whitespace-nowrap"
    (click)="showDialog()">
    {{'BOOKING_HOME.SEARCH_BOOKING' | translate}}
  </button>
</div>

<div class="search-booking-id-modal-section">
  <p-dialog header="{{'MY_BOOKING.SEARCH_YOUR_BOOKING' | translate}}" [(visible)]="visible" [modal]="true"
    [style]="{ width: '650px', height: 'auto' }" [draggable]="false" [resizable]="false" (onHide)="onCloseModal()">
    <div class="flex flex-col">
      <!-- <div class="w-full flex justify-evenly items-center !mb-7">
        <div *ngFor="let item of modalBookingTypeList">
          <div (click)="handleNavBookingType(item.short_name)" >
            <common-icon-text class="flex items-center gap-2 cursor-pointer h-full !px-[20px] !py-[8px] rounded-[40px] "
              [ngClass]="modalBookingType === item.short_name ? item.background_color : ''"
              [icon]="item.icon"
              [iconStyle]=" 'text-xl font-medium ' + (modalBookingType === item.short_name ? item.text_color : '')"
              [text]="item.booking_type_name"
              [textStyle]="'font-inter text-lg whitespace-nowrap font-semibold '+ (modalBookingType === item.short_name ? ''+ item.text_color : ' text-palette-gray-500')"></common-icon-text>
          </div>
        </div>
      </div> -->

      <div class="w-[100%] h-[56px] mb-3 flex gap-3 tel-input !px-5 !py-[14px]">
        <input
          class="w-[100%] h-[100%] outline-none font-inter text-lg font-normal text-palette-gray-900 uppercase placeholder:normal-case"
          [(ngModel)]="searchBookingId" (ngModelChange)="onSearchBookingIdInputChange($event)" type="text"
          placeholder="{{'MY_BOOKING.PLACEHOLDER.ENTER_ORDER_ID' | translate}}">
      </div>

      <div class="w-[100%] h-[56px] mb-2 flex items-center gap-3 tel-input !px-5 !py-[14px]">
        <input class="w-[95%] h-[100%] outline-none font-inter text-lg font-normal text-palette-gray-900" #searchInput
          [(ngModel)]="PINcode" (ngModelChange)="onSearchBookingIdInputChange($event)"
          type="{{this.isShowPinCode ? 'text' : 'password'}}"
          placeholder="{{'MY_BOOKING.PLACEHOLDER.PIN_CODE' | translate}}">
        <i (click)="togglePassword()"
          class="{{isShowPinCode ? 'sctr-icon-eye' : 'sctr-icon-eye-off'}} w-[24px] h-[24px] text-xl cursor-pointer text-palette-gray-700"></i>
      </div>

      <!-- Result search: hotel -->
      <div *ngIf="modalBookingType === 'hotel'">
        <div *ngIf="isEmptyObject(responseDataSearch) && isSearchBooking"
          class="flex flex-col justify-center items-center mt-3">
          <div class="w-[48px] h-[48px] rounded-full bg-palette-gray-50 flex justify-center items-center">
            <div class="w-[36px] h-[36px] rounded-full bg-palette-gray-100 flex justify-center items-center">
              <i class="sctr-icon-file-x-02 text-xl text-palette-gray-700"></i>
            </div>
          </div>
          <p class="font-inter text-lg font-semibold text-palette-gray-900 mt-2 mb-1">{{'MY_BOOKING.NO_RESULTS_FOUND' |
            translate}}</p>
          <p class="font-inter text-base font-normal text-palette-gray-600">{{'MY_BOOKING.NO_RESULTS_FOUND_DESCRIPTION'
            |translate}}</p>
          <p class="font-inter text-base font-normal text-palette-gray-600">{{'MY_BOOKING.PLEASE_TRY_ANOTHER_ID' |
            translate}}</p>
        </div>
        <div *ngIf="!isEmptyObject(responseDataSearch) && isSearchBooking">
          <div (click)="handleNavigateBookingSearch(responseDataSearch)"
            class="mt-3 cursor-pointer w-[100%] rounded-md p-2 bg-palette-gray-50 flex gap-3">
            <img *ngIf="responseDataSearch.hotel_image_overview_id"
              class="max-w-[80px] !h-[80px] rounded- object-cover" (error)="replaceWithDefaultImage($event)"
              src="{{baseImageId}}/{{responseDataSearch.hotel_image_overview_id}}.webp">
            <img *ngIf="!responseDataSearch.hotel_image_overview_id || responseDataSearch.hotel_image_overview_id === null || responseDataSearch.hotel_image_overview_id === undefined"
              class="max-w-[80px] !h-[80px] rounded-md object-cover" (error)="replaceWithDefaultImage($event)"
              src="{{responseDataSearch.hotel_image_overview_url}}">
            <div class="flex flex-col gap-2 w-[100%]">
              <common-icon-text class="flex items-center gap-2" [icon]="'sctr-icon-building-07'"
                [iconStyle]="'text-xl text-palette-gray-700'" [text]="responseDataSearch.hotel_name"
                [textStyle]="'text-xl font-semibold text-palette-gray-700'"></common-icon-text>
              <div class="w-[100%] flex justify-between">
                <div class="flex flex-col">
                  <p class="font-inter text-sm font-normal text-palette-gray-500">{{'COMMON.ORDER_ID' | translate}}</p>
                  <p class="font-inter text-base font-medium text-branding-primary-700">
                    {{responseDataSearch.reservation_code}}</p>
                </div>
                <div class="flex flex-col">
                  <p class="font-inter text-sm font-normal text-palette-gray-500">{{'PAYMENT.CHECK_IN' | translate}}</p>
                  <p class="font-inter text-base font-medium text-palette-gray-900">{{ responseDataSearch.check_in | dateFormat }}</p>
                </div>
                <div class="flex flex-col">
                  <p class="font-inter text-sm font-normal text-palette-gray-500">{{'PAYMENT.CHECK_OUT' | translate}}
                  </p>
                  <p class="font-inter text-base font-medium text-palette-gray-900">{{ responseDataSearch.check_out | dateFormat }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Result search: flight -->
      <div *ngIf="modalBookingType === 'flight'">
        <div *ngIf="isEmptyObject(responseDataSearch) && isSearchBooking"
          class="flex flex-col justify-center items-center mt-3">
          <div class="w-[48px] h-[48px] rounded-full bg-palette-gray-50 flex justify-center items-center">
            <div class="w-[36px] h-[36px] rounded-full bg-palette-gray-100 flex justify-center items-center">
              <i class="sctr-icon-file-x-02 text-xl text-palette-gray-700"></i>
            </div>
          </div>
          <p class="font-inter text-lg font-semibold text-palette-gray-900 mt-2 mb-1">{{'MY_BOOKING.NO_RESULTS_FOUND' |
            translate}}</p>
          <p class="font-inter text-base font-normal text-palette-gray-600">{{'MY_BOOKING.NO_RESULTS_FOUND_DESCRIPTION'
            |translate}}</p>
          <p class="font-inter text-base font-normal text-palette-gray-600">{{'MY_BOOKING.PLEASE_TRY_ANOTHER_ID' |
            translate}}</p>
        </div>
        <div *ngIf="!isEmptyObject(responseDataSearch) && isSearchBooking">
          <div class="mt-3 cursor-pointer">
            <div (click)="handleNavigateBookingSearch(responseDataSearch)"
              class="w-[100%] rounded-md p-2 bg-palette-gray-50 flex gap-3 font-inter">
              <div class="image-item rounded-lg {{responseDataSearch.one_way ? 'bg-blue-50' : 'bg-amber-50'}} 
                flex items-center justify-center flex-col gap-2 w-[68px]">
                <div class="{{responseDataSearch.one_way ? 'border-palette-blue-50 bg-palette-blue-100' : 'border-palette-amber-50 bg-palette-amber-100'}} 
                    !w-8 !h-8 rounded-full flex justify-center border ">
                  <ng-container *ngIf="responseDataSearch.one_way; else roundTrip">
                    <img src="./assets/images/flight-booking-simply/flight-icon-blue.svg" alt="">
                  </ng-container>
                  <ng-template #roundTrip>
                    <img src="./assets/images/flight-booking-simply/flight-yellow.svg" alt="">
                  </ng-template>
                </div>
                <p class="font-semibold not-italic text-[10px] whitespace-nowrap">
                  {{(responseDataSearch.one_way ? 'FLIGHT.SEARCH_RESULT.ONE_WAY' : 'FLIGHT.SEARCH_RESULT.ROUND_TRIP_1')
                  | translate}}
                </p>
              </div>
              <div class="xl:flex gap-3 font-inter">
                <!-- departure -->
                <div class="flex flex-col justify-end" [ngClass]="{'h-full': responseDataSearch.one_way}">
                  <a
                    class="text-palette-blue-600 text-sm font-medium not-italic mb-2.5">{{responseDataSearch.reservation_code}}</a>
                  <p class="text-palette-gray-900 font-medium text-base not-italic">
                    {{responseDataSearch.departure.city_name_departure}} ->
                    {{responseDataSearch.departure.city_name_arrival}}
                  </p>
                  <div class="flex gap-2 items-center">
                    <span><i class="sctr-icon-calendar text-palette-gray-500"></i></span>
                    <p class="text-palette-gray-500 font-normal	 text-sm not-italic truncate">
                      {{responseDataSearch.departure.time_departure_at.substring(0, 5)}} -
                      {{responseDataSearch.departure.time_arrival_at.substring(0, 5) }}
                    </p>
                    <i class="text-palette-gray-500 sctr-icon-solid-circle text-[6px]"></i>
                    <p class="text-palette-gray-500 font-normal	 text-sm not-italic truncate">
                      {{responseDataSearch.departure.date_departure_at | date: 'EEE, dd MMM yyyy'}}
                    </p>
                  </div>
                </div>

                <div *ngIf="!responseDataSearch.one_way" class="flex flex-col justify-end flex-1">
                  <p class="text-palette-gray-900 font-medium font-inter text-base not-italic">
                    {{responseDataSearch.arrival.city_name_departure}} ->
                    {{responseDataSearch.arrival.city_name_arrival}}
                  </p>
                  <div class="flex gap-2 items-center">
                    <span><i class="sctr-icon-calendar text-palette-gray-500"></i></span>
                    <p class="text-palette-gray-500 font-normal	 text-sm not-italic truncate">
                      {{responseDataSearch.arrival.time_departure_at.substring(0, 5)}} -
                      {{responseDataSearch.arrival.time_arrival_at.substring(0, 5) }}
                    </p>
                    <i class="text-palette-gray-500 sctr-icon-solid-circle text-[6px]"></i>
                    <p class="text-palette-gray-500 font-normal	 text-sm not-italic truncate">
                      {{responseDataSearch.arrival.date_departure_at |
                      date: 'EEE, dd MMM yyyy'}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <button (click)="handleClickSearch()" [disabled]="disableSearchButton || isLoading"
        class="w-[100%] h-[52px] {{disableSearchButton || isLoading ? 'bg-branding-primary-200' : 'bg-branding-primary-600 hover:bg-branding-primary-700'}}
          rounded-lg px-5 py-3 font-inter text-lg font-semibold text-white flex justify-center items-center mt-5 !mb-7">
        <div class="flex items-center !gap-3 ">
          <p>{{'COMMON.SEARCH_BOOKING' | translate}}</p>
          <div *ngIf="isLoading" class="flex items-center justify-center space-x-2 !mt-2">
            <div
              class="loader rounded-[50%] border-r-[2px] border-t-[2px] border-white opacity-1 h-[20px] w-[20px] mr-2 mb-2">
            </div>
          </div>
        </div>
      </button>
    </div>
  </p-dialog>
</div>