import {
  Component,
  EventEmitter,
  Output,
  QueryList,
  Renderer2,
  ViewChildren,
} from '@angular/core';
import {
  Input,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastType } from '@shared/enum/toast-type';
import {
  Room,
  RoomAmenityList,
} from '@modules/hotel-booking/models/search-available-room.model';
import { formatCurrency } from '@shared/utils/helper';
import { BookedRoomListService } from '@modules/hotel-booking/services/booked-list-rooms.service';
import { ViewDetailsService } from '@modules/hotel-booking/services/view-details.service';
import { ReservationService } from '@modules/hotel-booking/services/reservation-service/reservation.service';
import { TooltipPosition } from '@shared/enum/tooltip.enums';
import { environment } from '@env/environment';
import { SoctripTranslationService } from 'src/app/services/soctrip-translation/soctrip-translation.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/core/services/user/user.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-hotel-page-room-type',
  templateUrl: './hotel-page-room-type.component.html',
  styleUrls: ['./hotel-page-room-type.component.scss'],
  providers: [MessageService],
})
export class HotelPageRoomTypeComponent implements AfterViewInit, OnInit {
  @ViewChild('hotelFacility') hotelFacility: ElementRef;
  @ViewChildren('bedType') bedType: QueryList<ElementRef>;
  @Input() item: any;
  @Input() index: number;
  @Output() buyRoomType = new EventEmitter();
  @Output() selectedRoomType = new EventEmitter();

  public displayHotelFacilityList: RoomAmenityList[];
  public slicedItems: string;
  public remaining: number;
  public hasRemaining: boolean;
  is_support_multi_room: boolean;
  baseUrl = environment.BASE_PATH;
  tooltip_inclusions: string = '';
  resourceID: number;
  isClickedBook: boolean = false;
  selectedBedType: any = null;
  bedTypeArray: any;
  check_in: string;
  check_out: string;
  userInfo: any;
  currentLanguage: string = '';
  isShowInClusions: boolean = false;
  isShowTooltipNote: boolean = false;

  TooltipPosition: typeof TooltipPosition = TooltipPosition;

  constructor(
    private messageService: MessageService,
    private renderer: Renderer2,
    private cdRef: ChangeDetectorRef,
    private bookedRoomListService: BookedRoomListService,
    private viewDetailsService: ViewDetailsService,
    private reservationService: ReservationService,
    private soctripTranslationService: SoctripTranslationService,
    private router2: ActivatedRoute,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    this.viewDetailsService.getResourceID().subscribe((resourceID: number) => {
      this.resourceID = resourceID;
    });

    const user = JSON.parse(
      localStorage.getItem(environment.USER_PROFILE) || '{}'
    );
    this.userInfo = user;
  }

  ngOnInit(): void {
    this.item.room_amenity_list?.filter((item: any) => {
      if (item.amenity_name === 'Spa') {
        item.amenity_icon = 'Spa';
      }
    });

    this.router2.queryParams.subscribe((params) => {
      this.check_in = params['checkin'];
      this.check_out = params['checkout'];
    });

    this.displayHotelFacilityList = this.item.room_amenity_list?.slice(0, 8);

    this.viewDetailsService.getSupportMultiRoom().subscribe((data: boolean) => {
      this.is_support_multi_room = data;
    });

    if (Array.isArray(this.item)) {
      for (let obj of this.item) {
        obj.showTooltipPolicy = false;
        obj.showTooltipTaxes = false;
      }
    }
    this.currentLanguage = this.translateService.currentLang;
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  renderTooltipInclusion(inclusions: any): string {
    let tooltip_inclusions = '';
    for (let i = 1; i < inclusions.length; i++) {
      tooltip_inclusions += "<p class='flex !mt-1 items-center !gap-1 relative px-[6px] py-[2px] rounded-lg bg-palette-blue-50 text-palette-blue-600 max-w-fit'>";
      let inclusionKey = '';
      if (this.translateInclusions(inclusions[i]).includes('DETAIL')) {
        inclusionKey = this.translateService.instant(this.translateInclusions(inclusions[i]));
      } else {
        inclusionKey = this.translateInclusions(inclusions[i]);
      }
        
      tooltip_inclusions += inclusionKey;
      tooltip_inclusions += '</p>';
    }
    return tooltip_inclusions.trim();
  }

  public responsiveHotelFacility(index: number) {
    this.displayHotelFacilityList = this.item.room_amenity_list.slice(0, index);
    this.slicedItems = this.item.room_amenity_list.slice(index).join(', ');
    this.remaining = this.item.room_amenity_list.length - index;
    this.hasRemaining = true;
  }

  public handleClickView(event: Event) {
    event.stopPropagation();
    this.messageService.add({
      severity: ToastType.WARNING,
      detail: 'This feature is not developed yet',
    });
  }

  formatPrice(number: number) {
    return formatCurrency(number);
  }

  subtractNumberOfRoom(room: any) {
    if (room.numberRoomBuy !== 0) {
      room.numberRoomBuy--;
      this.bookedRoomListService.setBookedRoomList(
        room.room_type_id,
        'subtract'
      );
    }
    this.buyRoomType.emit(room);
  }

  plusNumberOfRoom(rate_plan: any, room: any) {
    rate_plan.numberRoomBuy++;
    this.bookedRoomListService.setBookedRoomList(
      rate_plan.room_type_id,
      'plus'
    );

    this.buyRoomType.emit(room);
  }

  onChooseBedType(room: any) {
    room.selectedBedType = this.selectedBedType.bed_id;
  }

  handleClickRoomHotel(room: any) {
    this.selectedRoomType.emit(room);
  }

  replaceWithDefaultImage(event: any): void {
    event.target.src = 'assets/images/rooms/default_room.png';
  }

  formatDollar(price: number) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(price);
  }

  handleClickBookRoom(rate_plan: any, room: any) {
    if (room.bed_types?.length > 1 && !this.selectedBedType) {
      this.messageService.add({
        severity: ToastType.WARNING,
        detail: this.soctripTranslationService.getTranslation(
          'MESSAGE.CHOOSE_BED_TYPE'
        ),
      });
      this.isClickedBook = true;
    } else {
      const data = {
        rate_plan: rate_plan,
        room: room,
        bed_id:
          room.bed_types?.length > 1
            ? this.selectedBedType?.bed_id
            : this.item.bed_types?.[0]?.bed_id,
      };
      this.reservationService.notiBookingSingleRoomType(data);
    }
  }

  getTotalTaxeCharge(taxes: any) {
    if (taxes === null) return 0;

    let totalTaxe = 0;
    for (const value of taxes) {
      if (!value.included_by_supplier) {
        totalTaxe += value.amount;
      }
    }

    return Number(totalTaxe.toFixed(2));
  }

  checkDisplayTaxesAndCharge(rate_plan: any) {
    if (
      rate_plan?.tax_data &&
      rate_plan?.tax_data?.taxes !== null &&
      rate_plan.tax_data.taxes.length > 0 &&
      rate_plan.tax_data.taxes[0].included_by_supplier != true
    ) {
      return true;
    } else {
      return false;
    }
  }

  convertToTitleCase(str: string): string {
    const words = str.split('_');

    const capitalizedWords = words.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });

    const result = capitalizedWords.join(' ');

    return result;
  }

  mouseOverBedType() {
    this.bedTypeArray = this.bedType.toArray();
    this.handleShowTooltip(this.bedTypeArray, 'hotel-bedtype');
  }

  handleShowTooltip(els: ElementRef[], className: string): void {
    for (var val of els) {
      const isTruncated =
        val.nativeElement.scrollWidth > val.nativeElement.clientWidth;
      if (isTruncated) {
        this.renderer.addClass(val.nativeElement, className);
      } else {
        this.renderer.removeClass(val.nativeElement, className);
      }
    }
  }

  calculateDateDifference(checkin: string, checkout: string): number {
    const checkinFormat = new Date(checkin);
    const checkoutFormat = new Date(checkout);

    const checkinTime = checkinFormat.getTime();
    const checkoutTime = checkoutFormat.getTime();

    const differenceInMs = Math.abs(checkoutTime - checkinTime);
    const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));

    return differenceInDays;
  }

  isBedAndBreakfast(meal: any) {
    let isBedAndBreakfast = false;
    if (meal.toLowerCase() == 'bed and breakfast') {
      isBedAndBreakfast = true;
    }
    return isBedAndBreakfast;
  }

  formatToTranslateMeal(meal: any) {
    return meal.replace(/[\s-]+/g, '_').toUpperCase(); 
  }

  renderTextInclusions(rate_plan: any) {
    return `+${rate_plan?.inclusions.length - 1}`;
  }

  convertToTitleCaseInClude(text: string): string {
    if (!text) return '';

    return (
      text.charAt(0).toUpperCase() +
      text.slice(1).toLowerCase().replace(/_/g, ' ')
    );
  }

  translateInclusions(text: string): string {
    if (!text) return '';
    let textTranslate = text;

    switch (text.trim()) {
      case 'Complimentary afternoon tea':
        textTranslate = 'DETAIL.COMPLIMENTARY_AFTERNOON_TEA';
        break;
      case 'Complimentary spa service':
        textTranslate = 'DETAIL.COMPLIMENTARY_SPA_SERVICE';
        break;
      case 'Free upgrade':
        textTranslate = 'DETAIL.FREE_UPGRADE';
        break;
      case 'Pool access':
        textTranslate = 'DETAIL.POOL_ACCESS';
        break;
      case 'Power':
        textTranslate = 'DETAIL.POWER';
        break;
      case 'Round-trip airport transfer':
        textTranslate = 'DETAIL.ROUND_TRIP_AIRPORT_TRANFER';
        break;
      case 'Shuttle bus':
        textTranslate = 'DETAIL.SHUTTLE_BUS';
        break;
      case 'Daily complimentary 2 or 4 bottles of mineral water subject to room category':
        textTranslate = 'DETAIL.DAILY_COMPLIMENTARY';
        break;
      case 'Free use gym machines & outdoor swimming pool':
        textTranslate = 'DETAIL.FREE_GYM_SWIMMING';
        break;
      case 'Others':
        textTranslate = 'DETAIL.OTHERS';
        break;
    }

    return textTranslate;
  }

  uppercaseLetter(text: string) {
    if (text === 'nomeal') {
      return 'NO_MEAL';
    }
    return text.toUpperCase();
  }
}
